<template>
  <Page v-bind="{ ...$props, ...$attrs }" landscape><slot></slot></Page>
</template>
<script>
import Page from './Page.vue';

export default {
  components: {
    Page
  }
};
</script>
<style lang="scss">
@page {
  size: a4 landscape !important;
}
</style>
