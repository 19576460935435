<template>
  <div v-if="prevValue && !prevValueLoading">
    <span
      :class="{
        'text-danger': inverse ? getPctDiff(value, prevValue).negative : !getPctDiff(value, prevValue).negative,
        'text-success': inverse ? !getPctDiff(value, prevValue).negative : getPctDiff(value, prevValue).negative
      }"
    >
      <i
        class="fa"
        :class="{
          'fa-caret-up': inverse ? getPctDiff(value, prevValue).negative : !getPctDiff(value, prevValue).negative,
          'fa-caret-down': inverse ? !getPctDiff(value, prevValue).negative : getPctDiff(value, prevValue).negative
        }"
      ></i>
      {{ getPctDiff(value, prevValue).value | numberFormat(precision) }}%</span
    >
    <span v-if="!hideText" :class="{ 'text-muted': !isReport }"> from last {{ comparisonPeriod }}</span>
  </div>

  <div v-else-if="!prevValue && !prevValueLoading" class="ConsumptionCard--delta" :class="{ 'text-muted': !isReport }">
    <i class="fa fa-hyphen"></i>
    0% <span v-if="!hideText" :class="{ 'text-muted': !isReport }"> from last {{ comparisonPeriod }}</span>
  </div>
  <div v-else>
    <i class="fa fa-spinner-third fa-spin"></i>
  </div>
</template>
<script>
export default {
  props: {
    value: {
      type: Number,
      default: 0,
      required: false
    },
    prevValue: {
      type: Number,
      default: null
    },
    prevValueLoading: {
      type: Boolean,
      default: null
    },
    comparisonPeriod: {
      type: String,
      default: 'month'
    },
    inverse: {
      type: Boolean,
      default: false
    },
    isReport: {
      type: Boolean,
      default: false
    },
    hideText: {
      type: Boolean,
      default: false
    },
    precision: {
      type: [Number, String],
      default: 0
    }
  },
  methods: {
    getPctDiff(curr = 1, prev = 1) {
      return {
        negative: curr < prev,
        value: (Math.abs(curr - prev) / prev) * 100
      };
    }
  }
};
</script>
