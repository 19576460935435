var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.prevValue && !_vm.prevValueLoading ? _c('div', [_c('span', {
    class: {
      'text-danger': _vm.inverse ? _vm.getPctDiff(_vm.value, _vm.prevValue).negative : !_vm.getPctDiff(_vm.value, _vm.prevValue).negative,
      'text-success': _vm.inverse ? !_vm.getPctDiff(_vm.value, _vm.prevValue).negative : _vm.getPctDiff(_vm.value, _vm.prevValue).negative
    }
  }, [_c('i', {
    staticClass: "fa",
    class: {
      'fa-caret-up': _vm.inverse ? _vm.getPctDiff(_vm.value, _vm.prevValue).negative : !_vm.getPctDiff(_vm.value, _vm.prevValue).negative,
      'fa-caret-down': _vm.inverse ? !_vm.getPctDiff(_vm.value, _vm.prevValue).negative : _vm.getPctDiff(_vm.value, _vm.prevValue).negative
    }
  }), _vm._v(" " + _vm._s(_vm._f("numberFormat")(_vm.getPctDiff(_vm.value, _vm.prevValue).value, _vm.precision)) + "%")]), !_vm.hideText ? _c('span', {
    class: {
      'text-muted': !_vm.isReport
    }
  }, [_vm._v(" from last " + _vm._s(_vm.comparisonPeriod))]) : _vm._e()]) : !_vm.prevValue && !_vm.prevValueLoading ? _c('div', {
    staticClass: "ConsumptionCard--delta",
    class: {
      'text-muted': !_vm.isReport
    }
  }, [_c('i', {
    staticClass: "fa fa-hyphen"
  }), _vm._v(" 0% "), !_vm.hideText ? _c('span', {
    class: {
      'text-muted': !_vm.isReport
    }
  }, [_vm._v(" from last " + _vm._s(_vm.comparisonPeriod))]) : _vm._e()]) : _c('div', [_c('i', {
    staticClass: "fa fa-spinner-third fa-spin"
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }