<template>
  <div class="report-container" :class="{ 'is-preview': preview }">
    <div class="report-pages">
      <slot></slot>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Pages',
  props: {
    preview: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      pages: []
    };
  },
  mounted() {
    this.pages = this.$children
      .filter(c => ['Page', 'PageLandscape'].includes(c.$options._componentTag) && !c._props?.title)
      .map(child => child._uid);
  },
  provide() {
    return {
      pages: () => this.pages
    };
  }
};
</script>
<style lang="scss">
.report-container {
  position: relative;
  background: #888;
  padding: 4em 0;

  &.is-preview {
    background: none;
    padding: 0;

    position: relative;
    zoom: 0.5;
  }
}

@media print {
  .report-container {
    padding: 0;
    background: #fff;
  }
  .report-pages {
    margin: 0;
  }

  #jsd-widget {
    display: none !important;
  }
}
</style>
