<template>
  <Pages title="Asset Summary">
    <PageLandscape no-header no-footer>
      <div class="row">
        <div class="col-md-3 pr-4">
          <div v-if="type === 'asset'" class="mb-3">
            <div class="mb-2">
              <h6 class="text-muted mb-0">{{ period.startDate | date('MMM YYYY') }} to {{ period.endDate | date('MMM YYYY') }}</h6>
              <h3 class="text-primary mb-0">{{ asset.siteName }}</h3>
              <h6 class="mb-0">{{ asset.addressString }}</h6>
            </div>
            <div class="asset-image mb-2">
              <img class="img-fluid" :src="(asset.images?.find(i => i.isPrimary) || asset.images[0])?.url" alt="Asset Image" />
            </div>
            <div class="asset-info">
              <PropertyListItem col-size="col-lg-4" class="mb-2 font-w600" title="Floor Area">
                {{ floorAreaMetric | numberFormat(0) }} m<sup>2</sup>
                <span v-if="asset.floorAreaUnit === 'imperial'">
                  ({{ asset.floorArea | numberFormat(0) }} {{ asset.floorAreaUnit | floorAreaUnit }})</span
                ></PropertyListItem
              >
            </div>
          </div>
          <div v-else class="mb-3">
            <div class="mb-2">
              <h6 class="text-muted mb-0">{{ period.startDate | date('MMM YYYY') }} to {{ period.endDate | date('MMM YYYY') }}</h6>
              <h3 class="text-primary mb-0">{{ assetGroup.name }}</h3>
            </div>
            <div class="asset-info">
              <PropertyListItem col-size="col-lg-6" class="font-w600" title="Assets">
                {{ assetGroupAssets.length | numberFormat(0) }}
              </PropertyListItem>
              <PropertyListItem col-size="col-lg-6" class="font-w600" title="Total Floor Area">
                {{ floorAreaMetric | numberFormat(0) }} m<sup>2</sup>
              </PropertyListItem>
              <PropertyListItem col-size="col-lg-6" class="font-w600" title="Avg. Floor Area">
                {{ (floorAreaMetric / assetGroupAssets.length) | numberFormat(0) }} m<sup>2</sup>
              </PropertyListItem>
            </div>
          </div>

          <div v-if="consumption.waste" class="consumption-box mb-3">
            <div class="d-flex justify-content-between align-items-center mb-4">
              <div class="font-w600 text-muted font-size-h4"><i class="fa fa-dumpster mr-1"></i> Waste</div>
              <div>
                <span class="font-w600 font-size-h4 mr-2">{{ consumption.waste.total | numberFormat(0) }} kg</span>
                <span class="font-w600 text-muted small">Total Waste</span>
              </div>
            </div>
            <div class="font-w600 text-success mb-1"><i class="fa fa-recycle fa-fw"></i> Recycled</div>
            <div class="bars">
              <div class="bars-bar success" :style="`width: ${consumption.waste.recycledPctWidth}px`"></div>
              <div class="bars-text">{{ consumption.waste.recycledPct }}% ({{ consumption.waste.recycled | numberFormat(0) }} kg)</div>
            </div>
            <div class="font-w600 text-muted mb-1"><i class="fa fa-dumpster fa-fw"></i> EfW</div>
            <div class="bars waste">
              <div class="bars-bar" :style="`width: ${consumption.waste.efwPctWidth}px`"></div>
              <div class="bars-text">{{ consumption.waste.efwPct }}% ({{ consumption.waste.efw | numberFormat(0) }} kg)</div>
            </div>
            <div class="font-w600 text-brown mb-1"><i class="fa fa-apple-core fa-fw"></i> Composted</div>
            <div class="bars waste">
              <div class="bars-bar brown" :style="`width: ${consumption.waste.compostPctWidth}px`"></div>
              <div class="bars-text">{{ consumption.waste.compostPct }}% ({{ consumption.waste.compost | numberFormat(0) }} kg)</div>
            </div>
          </div>

          <div v-if="type === 'asset'" class="consumption-box mb-3">
            <div class="asset-epc">
              <div class="font-w600 text-muted mb-3 font-size-h4"><i class="fa fa-file-certificate mr-1"></i> EPC</div>
              <div class="epc" :class="{ selected: epcCert?.certificate?.rating === 'A' }">
                <div class="epc-score a">A</div>
                <div class="epc-pointer a">
                  <small>0-25</small
                  ><span v-if="epcCert?.certificate?.rating === 'A'">{{ epcCert.certificate.score }} | {{ epcCert.certificate.rating }}</span>
                </div>
              </div>
              <div class="epc" :class="{ selected: epcCert?.certificate?.rating === 'B' }">
                <div class="epc-score b">B</div>
                <div class="epc-pointer b">
                  <small>26-50</small
                  ><span v-if="epcCert?.certificate?.rating === 'B'">{{ epcCert.certificate.score }} | {{ epcCert.certificate.rating }}</span>
                </div>
              </div>
              <div class="epc" :class="{ selected: epcCert?.certificate?.rating === 'C' }">
                <div class="epc-score c">C</div>
                <div class="epc-pointer c">
                  <small>51-75</small
                  ><span v-if="epcCert?.certificate?.rating === 'C'">{{ epcCert.certificate.score }} | {{ epcCert.certificate.rating }}</span>
                </div>
              </div>
              <div class="epc" :class="{ selected: epcCert?.certificate?.rating === 'D' }">
                <div class="epc-score d">D</div>
                <div class="epc-pointer d">
                  <small>76-100</small
                  ><span v-if="epcCert?.certificate?.rating === 'D'">{{ epcCert.certificate.score }} | {{ epcCert.certificate.rating }}</span>
                </div>
              </div>
              <div class="epc" :class="{ selected: epcCert?.certificate?.rating === 'E' }">
                <div class="epc-score e">E</div>
                <div class="epc-pointer e">
                  <small>101-125</small
                  ><span v-if="epcCert?.certificate?.rating === 'E'">{{ epcCert.certificate.score }} | {{ epcCert.certificate.rating }}</span>
                </div>
              </div>
              <div class="epc" :class="{ selected: epcCert?.certificate?.rating === 'F' }">
                <div class="epc-score f">F</div>
                <div class="epc-pointer f">
                  <small>126-150</small
                  ><span v-if="epcCert?.certificate?.rating === 'F'">{{ epcCert.certificate.score }} | {{ epcCert.certificate.rating }}</span>
                </div>
              </div>
              <div class="epc" :class="{ selected: epcCert?.certificate?.rating === 'G' }">
                <div class="epc-score g">G</div>
                <div class="epc-pointer g">
                  <small>150+</small
                  ><span v-if="epcCert?.certificate?.rating === 'G'">{{ epcCert.certificate.score }} | {{ epcCert.certificate.rating }}</span>
                </div>
              </div>
            </div>
          </div>

          <div v-if="type === 'assetGroup'" class="consumption-box mb-3">
            <div class="asset-epc">
              <div class="font-w600 text-muted mb-3 font-size-h4"><i class="fa fa-file-certificate mr-1"></i> EPC Count</div>
              <div class="epc counter">
                <div class="epc-score a">A</div>
                <div class="epc-pointer a">
                  <small>0-25</small><span>{{ analytics['epc-ratings'].results['A'] }}</span>
                </div>
              </div>
              <div class="epc counter">
                <div class="epc-score b">B</div>
                <div class="epc-pointer b">
                  <small>26-50</small><span>{{ analytics['epc-ratings'].results['B'] }}</span>
                </div>
              </div>
              <div class="epc counter">
                <div class="epc-score c">C</div>
                <div class="epc-pointer c">
                  <small>51-75</small><span>{{ analytics['epc-ratings'].results['C'] }}</span>
                </div>
              </div>
              <div class="epc counter">
                <div class="epc-score d">D</div>
                <div class="epc-pointer d">
                  <small>76-100</small><span>{{ analytics['epc-ratings'].results['D'] }}</span>
                </div>
              </div>
              <div class="epc counter">
                <div class="epc-score e">E</div>
                <div class="epc-pointer e">
                  <small>101-125</small><span>{{ analytics['epc-ratings'].results['E'] }}</span>
                </div>
              </div>
              <div class="epc counter">
                <div class="epc-score f">F</div>
                <div class="epc-pointer f">
                  <small>126-150</small><span>{{ analytics['epc-ratings'].results['F'] }}</span>
                </div>
              </div>
              <div class="epc counter">
                <div class="epc-score g">G</div>
                <div class="epc-pointer g">
                  <small>150+</small><span>{{ analytics['epc-ratings'].results['G'] }}</span>
                </div>
              </div>
            </div>
          </div>

          <div class="consumption-box">
            <div class="row align-items-center">
              <div class="col-md-5">
                <div class="font-w600 text-muted font-size-h4"><i class="fa fa-wind-turbine mr-1"></i> Utility Renewables</div>
              </div>
              <div class="col">
                <div class="font-w600 text-muted"><i class="fa fa-bolt mr-1 text-warning"></i></div>
                <span class="font-w600 font-size-h3">{{ contractRenewables.elec }}</span>
              </div>
              <div class="col">
                <div class="font-w600 text-muted"><i class="fa fa-fire-flame-simple mr-1 text-danger"></i></div>
                <span class="font-w600 font-size-h3">{{ contractRenewables.gas }}</span>
              </div>
            </div>
            <!-- <p class="font-size-sm mb-0">
  Utility companies provide different a different mix of sources for electricity and gas. The above shows the renewable
  percentages provided on this asset's utility contracts.
</p> -->
          </div>
        </div>

        <div class="col-md-9">
          <div class="row mb-3">
            <div class="col">
              <div class="consumption-box mb-2">
                <div class="font-w600 text-muted mb-2 font-size-h4"><i class="fa fa-bolt mr-1 text-warning"></i> Electricity</div>
                <div class="mb-0">
                  <span class="font-w600 font-size-h2">
                    <!-- eslint-disable vue/no-parsing-error -->
                    {{
                      (consumptionSummary.elec.total / floorAreaMetric) | numberFormat(consumptionSummary.elec.total / floorAreaMetric < 10 ? 2 : 0)
                    }}</span
                  >
                  <span class="font-w600 text-muted"> kWh/m<sup>2</sup></span>
                </div>
                <div class="small font-w600">
                  <PctDiff :value="consumptionSummary.elec.total" :prev-value="consumptionSummary.elec.prev" comparison-period="year" />
                </div>
              </div>

              <!-- <p class="font-size-sm mb-0">
                Electricity consumption is currently <strong>on track</strong> to remain within the 1,000 kWh target set for this year.
              </p> -->
            </div>
            <div class="col">
              <div class="consumption-box mb-2">
                <div class="font-w600 text-muted mb-2 font-size-h4"><i class="fa fa-fire-flame-simple mr-1 text-danger"></i> Gas</div>
                <div class="mb-0">
                  <span class="font-w600 font-size-h2">
                    <!-- eslint-disable-next-line -->
                    {{
                      (consumptionSummary.gas.total / floorAreaMetric) | numberFormat(consumptionSummary.gas.total / floorAreaMetric < 10 ? 2 : 0)
                    }}</span
                  >
                  <span class="font-w600 text-muted"> kWh/m<sup>2</sup></span>
                </div>
                <div class="small font-w600">
                  <PctDiff :value="consumptionSummary.gas.total" :prev-value="consumptionSummary.gas.prev" comparison-period="year" />
                </div>
              </div>

              <!-- <p class="font-size-sm mb-0">Gas consumption is <strong>on track</strong> to keep within the 800 kWh target for the current year.</p> -->
            </div>
            <div class="col">
              <div class="consumption-box mb-2">
                <div class="font-w600 text-muted mb-2 font-size-h4"><i class="fa fa-solar-panel mr-1 text-success"></i> Solar</div>
                <div class="mb-0">
                  <span class="font-w600 font-size-h2">
                    <!-- eslint-disable invalid-first-character-of-tag-name -->
                    {{
                      (consumptionSummary.solar.total / floorAreaMetric) | numberFormat(consumptionSummary.solar.total / floorAreaMetric < 10 ? 2 : 0)
                    }}</span
                  >
                  <span class="font-w600 text-muted"> kWh/m<sup>2</sup></span>
                </div>
                <div class="small font-w600">
                  <PctDiff :value="consumptionSummary.solar.total" :prev-value="consumptionSummary.solar.prev" comparison-period="year" />
                </div>
              </div>

              <!-- <p class="font-size-sm mb-0">
                Solar generation <strong>may not reach the current target</strong> set of 200 kWh, based on our AI prediction model for solar.
              </p> -->
            </div>
            <div class="col">
              <div class="consumption-box mb-2">
                <div class="font-w600 text-muted mb-2 font-size-h4"><i class="fa fa-faucet mr-1 text-info"></i> Water</div>
                <div class="mb-0">
                  <span class="font-w600 font-size-h2">
                    <!-- eslint-disable-next-line -->
                    {{
                      (consumptionSummary.water.total / floorAreaMetric) | numberFormat(consumptionSummary.water.total / floorAreaMetric < 10 ? 2 : 0)
                    }}</span
                  >
                  <span class="font-w600 text-muted"> m<sup>3</sup>/m<sup>2</sup></span>
                </div>
                <!-- <div class="mb-2">
                  <span class="font-w600 font-size-h4">10.1</span> <small class="font-w600 text-muted">m<sup>3</sup>/m<sup>2</sup></small>
                </div> -->
                <div class="small font-w600">
                  <PctDiff :value="consumptionSummary.water.total" :prev-value="consumptionSummary.water.prev" comparison-period="year" />
                </div>
              </div>
              <!-- 
              <p class="font-size-sm mb-0">
                Water is set to remain <strong>well within the target</strong> set of 2,000 m<sup>3</sup> for this year.
              </p> -->
            </div>
          </div>

          <div class="row mb-1">
            <div class="col-md-6">
              <div class="consumption-box mb-4">
                <div class="font-w600 text-muted mb-0 font-size-h5"><i class="fa fa-bolt mr-1"></i> Electricity & Gas Consumption</div>
                <apexchart
                  v-if="analytics['elec-consumption']?.results?.monthlyConsumption"
                  height="364"
                  :options="benchmarkConsumptionLineChart.options"
                  :series="benchmarkConsumptionLineChart.series"
                />
              </div>
            </div>
            <div class="col-md-6">
              <div class="consumption-box mb-4">
                <div class="row">
                  <div class="col-md-6">
                    <div class="font-w600 text-muted mb-0 font-size-h5"><i class="fa fa-faucet mr-1"></i> Water Consumption</div>
                    <apexchart
                      v-if="analytics['water-consumption']?.results?.monthlyConsumption"
                      height="142"
                      :options="consumptionCharts.water.options"
                      :series="consumptionCharts.water.series"
                    />
                  </div>
                  <div class="col-md-6">
                    <div class="font-w600 text-muted mb-0 font-size-h5"><i class="fa fa-solar-panel mr-1"></i> Solar Generation</div>
                    <apexchart
                      v-if="analytics['solar-consumption']?.results?.monthlyConsumption"
                      height="143"
                      :options="consumptionCharts.solar.options"
                      :series="consumptionCharts.solar.series"
                    />
                  </div>
                </div>
              </div>
              <div class="consumption-box consumption-box--trimmed mb-4">
                <div class="font-w600 text-muted mb-0 font-size-h5"><i class="fa fa-line-chart mr-2"></i>Benchmarks</div>
                <div class="row">
                  <div class="col-md-6">
                    <apexchart
                      v-if="analytics['elec-consumption']?.results"
                      height="145"
                      :options="elecBenchmarkChart.options"
                      :series="elecBenchmarkChart.series"
                    />
                  </div>
                  <div class="col-md-6">
                    <apexchart
                      v-if="analytics['elec-consumption']?.results && analytics['gas-consumption']?.results"
                      height="145"
                      :options="combinedBenchmarkChart.options"
                      :series="combinedBenchmarkChart.series"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="consumption-box">
            <div class="d-flex justify-content-between align-items-start">
              <div class="font-w600 text-muted mb-3 font-size-h3"><i class="fa fa-leaf mr-1 text-success"></i> Emissions</div>
              <p class="font-size-sm font-w600 text-muted mb-0">The below values are all calculated using the BEIS emissions factors for 2023.</p>
            </div>
            <div class="border-bottom pb-3">
              <div class="row">
                <div class="col">
                  <div class="font-w600 text-muted mb-1">Scope 1</div>
                  <div>
                    <span class="font-w600 font-size-h3">{{ emissionSummary.scope1.total | numberFormat(0) }} </span>
                    <small class="font-w600 text-muted">kg CO<sub>2</sub>e</small>
                  </div>
                  <div class="small font-w600">
                    <PctDiff :value="emissionSummary.scope1.total" :prev-value="emissionSummary.scope1.prev[0]" comparison-period="year" />
                  </div>
                </div>
                <div class="col">
                  <div class="font-w600 text-muted mb-1">Scope 2</div>
                  <div>
                    <span class="font-w600 font-size-h3">{{ emissionSummary.scope2.total | numberFormat(0) }} </span>
                    <small class="font-w600 text-muted">kg CO<sub>2</sub>e</small>
                  </div>
                  <div class="small font-w600">
                    <PctDiff :value="emissionSummary.scope2.total" :prev-value="emissionSummary.scope2.prev[0]" comparison-period="year" />
                  </div>
                </div>
                <div class="col">
                  <div class="font-w600 text-muted mb-1">Scope 3</div>
                  <div>
                    <span class="font-w600 font-size-h3">{{ emissionSummary.scope3.total | numberFormat(0) }} </span>
                    <small class="font-w600 text-muted">kg CO<sub>2</sub>e</small>
                  </div>
                  <div class="small font-w600">
                    <PctDiff :value="emissionSummary.scope3.total" :prev-value="emissionSummary.scope3.prev[0]" comparison-period="year" />
                  </div>
                </div>
                <div class="col">
                  <div class="font-w600 text-muted mb-1">Total</div>
                  <div>
                    <span class="font-w600 font-size-h3">{{ emissionSummary.combined.total | numberFormat(0) }} </span>
                    <small class="font-w600 text-muted">kg CO<sub>2</sub>e</small>
                  </div>
                  <div class="small font-w600">
                    <PctDiff :value="emissionSummary.combined.total" :prev-value="emissionSummary.combined.prev[0]" comparison-period="year" />
                  </div>
                </div>
                <div class="col">
                  <div class="font-w600 text-muted mb-1">Intensity</div>
                  <div>
                    <span class="font-w600 font-size-h3">{{ emissionSummary.intensity.total | numberFormat(0) }} </span>
                    <small class="font-w600 text-muted">kg CO<sub>2</sub>e/m<sup>2</sup></small>
                  </div>
                  <div class="small font-w600">
                    <PctDiff :value="emissionSummary.intensity.total" :prev-value="emissionSummary.intensity.prev[0]" comparison-period="year" />
                  </div>
                </div>
              </div>
            </div>
            <div class="row align-items-center">
              <div class="col-md-2">
                <apexchart height="220" :options="scopeBreakdownChart.options" :series="scopeBreakdownChart.series" />
              </div>

              <div class="col-md-5">
                <apexchart height="220" :options="emissionsBreakdownChart.options" :series="emissionsBreakdownChart.series" />
              </div>

              <div class="col-md-5">
                <apexchart
                  v-if="analytics['solar-consumption']?.results?.monthlyConsumption"
                  height="200"
                  :options="emissionsChartLine.options"
                  :series="emissionsChartLine.series"
                />
              </div>
            </div>
          </div>

          <!-- Benchmarks START -->
          <!-- <div class="col-md-4">
              <div class="font-w600 text-secondary mb-3 font-size-h3"><i class="fa fa-chart-column mr-1"></i> Benchmarks</div>

              <div class="mb-3 pb-3 border-bottom">
                <div class="d-flex align-items-center mb-3">
                  <div>
                    <img
                      class="benchmark-logo benchmark-logo--bbp pr-3"
                      src="https://www.betterbuildingspartnership.co.uk/themes/pippip/dist/images/logo.svg"
                    />
                  </div>
                  <div>
                    <strong>REEB</strong>
                    <p class="font-size-sm mb-0">Office Air Conditioned</p>
                  </div>
                </div>
                <p class="font-size-sm mb-1">
                  REEB benchmark for 2020 suggests this asset has a
                  <strong>Good</strong> rating for electricity and a <strong>Good</strong> rating for gas. Benchmark data for 2020:
                </p>
                <p class="font-size-sm mb-0">
                  Electricity: Good 124, Typical 174 (kWh/m<sup>2</sup>/yr). <br />Gas: Good 30, Typical 63 (kWh/m<sup>2</sup>/yr).
                </p>
              </div>

              <div class="mb-3 pb-3 border-bottom">
                <div class="d-flex align-items-center mb-3">
                  <div>
                    <img
                      class="benchmark-logo pr-3"
                      src="https://i1.wp.com/asbp.org.uk/wp-content/uploads/2021/05/ooVBtPgG-2.png?fit=400%2C400&ssl=1"
                    />
                  </div>
                  <div>
                    <strong>LETI</strong>
                    <p class="font-size-sm mb-0">Commercial offices</p>
                  </div>
                </div>
                <p class="font-size-sm mb-0">
                  The LETI 2020 benchmark requires reducing energy consumption to 55 kWh/m<sup>2</sup>.yr and heating demand to 15
                  kWh/m<sup>2</sup>.yr.
                  <strong>This asset met both targets.</strong>
                </p>
              </div>

              <div>
                <div class="d-flex align-items-center mb-3">
                  <div>
                    <img class="benchmark-logo pr-3" src="https://www.vercoglobal.com/assets/images/news/800/5304_crrem.png" />
                  </div>
                  <div>
                    <strong>CRREM</strong>
                    <p class="font-size-sm mb-0">Office 1.5°</p>
                  </div>
                </div>
                <p class="font-size-sm mb-0">
                  This asset is currently <strong>on track</strong> with the CREEM 1.5° scenario pathway (2022: 193.9 kWh/m<sup>2</sup>.yr).
                </p>
              </div>
            </div> -->
          <!-- Benchmarks END -->
        </div>
      </div>
    </PageLandscape>
    <!-- <Page landscape> </Page> -->
  </Pages>
</template>
<script>
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';

import PageLandscape from './components/PageLandscape';
import Pages from './components/Pages';
import PropertyListItem from '@/components/base/PropertyListItem';
import PctDiff from '@/components/analytics/PctDiff';
import { capitalize, numberFormat } from '@/lib/helpers';

export default {
  name: 'AssetSummaryPDF',
  components: {
    PageLandscape,
    Pages,
    PctDiff,
    PropertyListItem
  },
  data() {
    return {
      error: '',
      types: ['electricity', 'solar', 'gas', 'water'],
      period: {
        startDate: moment().subtract(1, 'year').startOf('year'),
        endDate: moment().subtract(1, 'year').endOf('year'),
        prevStartDate: moment().subtract(2, 'year').startOf('year'),
        prevEndDate: moment().subtract(2, 'year').endOf('year')
      },
      options: {},
      consumption: {
        waste: null
      },
      type: 'asset'
    };
  },
  computed: {
    ...mapGetters({
      asset: 'asset/asset',
      assetGroup: 'assetGroup/assetGroup',
      company: 'company/company',
      report: 'report/report',
      analytics: 'analytics/analytics',
      loadingAction: 'analytics/loadingAction',
      errorAction: 'analytics/errorAction',
      analyticsOptions: 'analytics/options',
      benchmarks: 'benchmark/benchmarks',
      assetGroupAssets: 'assetGroup/assets'
    }),
    floorAreaMetric() {
      if (this.type === 'asset') {
        return this.asset.floorAreaUnit === 'metric' ? this.asset.floorArea : this.asset.floorArea * 0.092903;
      } else if (this.assetGroupAssets) {
        return this.assetGroupAssets.reduce(
          (acc, asset) => (asset.floorAreaUnit === 'imperial' ? asset.floorArea * 0.092903 : asset.floorArea) + acc,
          0
        );
      }

      return 1;
    },
    contractRenewables() {
      const results = { elec: 'N/A', gas: 'N/A' };
      if (!this.analytics['contract-renewable']?.results) return results;

      if (this.analytics['contract-renewable']?.results?.electricity) {
        results.elec = numberFormat(this.analytics['contract-renewable']?.results?.electricity) + '%';
      }

      if (this.analytics['contract-renewable']?.results?.gas) {
        results.gas = numberFormat(this.analytics['contract-renewable']?.results?.gas) + '%';
      }

      return results;
    },
    assetBenchmarks() {
      return this.benchmarks.map(benchmark => {
        const categoryField = this.asset.benchmarks.find(b => b.fieldCode === 'category' && b.benchmarkId === benchmark._id);
        const scenarioField = this.asset.benchmarks.find(b => b.fieldCode === 'scenario' && b.benchmarkId === benchmark._id);

        // Sort benchmark data by year starting from most recent
        benchmark.data.sort((a, b) => {
          return b.year - a.year;
        });

        const relevantData = benchmark.data[0].values.filter(v => {
          // If this asset has no category field set, but this benchmark requires it, skip
          if (!categoryField && v.category) return false;

          // If this asset has no scenario field set, but this benchmark requires it, skip
          if (!scenarioField && v.scenario) return false;

          if (v.category === categoryField?.fieldValue && v.scenario === scenarioField?.fieldValue) {
            return v;
          }
        });

        return relevantData;
      });
    },
    consumptionSummary() {
      const results = {
        elec: { total: 0, prev: 0 },
        gas: { total: 0, prev: 0 },
        solar: { total: 0, prev: 0 },
        water: { total: 0, prev: 0 }
      };

      if (this.floorAreaMetric === 0) {
        return results;
      }

      if (this.analytics['elec-consumption']?.results) {
        const consumption = this.analytics['elec-consumption']?.results?.totalConsumption;
        const prevConsumption = this.analytics['elec-consumption-prev']?.results?.totalConsumption;

        if (consumption) {
          results.elec.total = consumption;

          if (prevConsumption) {
            results.elec.prev = prevConsumption;
          }
        }
      }

      if (this.analytics['gas-consumption']?.results) {
        const consumption = this.analytics['gas-consumption']?.results?.totalConsumption;
        const prevConsumption = this.analytics['gas-consumption-prev']?.results?.totalConsumption;

        if (consumption) {
          results.gas.total = consumption;

          if (prevConsumption) {
            results.gas.prev = prevConsumption;
          }
        }
      }

      if (this.analytics['water-consumption']?.results) {
        const consumption = this.analytics['water-consumption']?.results?.totalConsumption;
        const prevConsumption = this.analytics['water-consumption-prev']?.results?.totalConsumption;

        if (consumption) {
          results.water.total = consumption;

          if (prevConsumption) {
            results.water.prev = prevConsumption;
          }
        }
      }

      if (this.analytics['solar-consumption']?.results) {
        const consumption = this.analytics['solar-consumption']?.results?.totalConsumption;
        const prevConsumption = this.analytics['solar-consumption-prev']?.results?.totalConsumption;

        if (consumption) {
          results.solar.total = consumption;

          if (prevConsumption) {
            results.solar.prev = prevConsumption;
          }
        }
      }

      return results;
    },
    emissionSummary() {
      const results = {
        scope1: { total: 0, prev: [0, 0, 0, 0, 0] },
        scope2: { total: 0, prev: [0, 0, 0, 0, 0] },
        scope3: { total: 0, prev: [0, 0, 0, 0, 0] },
        combined: { total: 0, prev: [0, 0, 0, 0, 0] },
        intensity: { total: 0, prev: [0, 0, 0, 0, 0] }
      };

      if (this.floorAreaMetric === 0) {
        return results;
      }

      if (this.analytics['asset-emissions']?.results) {
        const emissions = this.analytics['asset-emissions']?.results;
        const prevEmissions = this.analytics['asset-emissions-prev']?.results;
        const prevEmissions2 = this.analytics['asset-emissions-prev2']?.results;
        const prevEmissions3 = this.analytics['asset-emissions-prev3']?.results;
        const prevEmissions4 = this.analytics['asset-emissions-prev4']?.results;
        const prevEmissions5 = this.analytics['asset-emissions-prev5']?.results;

        if (emissions) {
          results.scope1.total = emissions.scope1;
          results.scope2.total = emissions.scope2;
          results.scope3.total = emissions.scope3;
          results.combined.total = results.scope1.total + results.scope2.total + results.scope3.total;
          results.intensity.total = results.combined.total / this.floorAreaMetric;
        }

        if (prevEmissions) {
          results.scope1.prev[0] = prevEmissions.scope1;
          results.scope2.prev[0] = prevEmissions.scope2;
          results.scope3.prev[0] = prevEmissions.scope3;
          results.combined.prev[0] = prevEmissions.scope1 + prevEmissions.scope2 + prevEmissions.scope3;
          results.intensity.prev[0] = results.combined.total / this.floorAreaMetric;
        }

        if (prevEmissions2) {
          results.scope1.prev[1] = prevEmissions2.scope1;
          results.scope2.prev[1] = prevEmissions2.scope2;
          results.scope3.prev[1] = prevEmissions2.scope3;
          results.combined.prev[1] = prevEmissions2.scope1 + prevEmissions2.scope2 + prevEmissions2.scope3;
          results.intensity.prev[1] = results.combined.total / this.floorAreaMetric;
        }

        if (prevEmissions3) {
          results.scope1.prev[2] = prevEmissions3.scope1;
          results.scope2.prev[2] = prevEmissions3.scope2;
          results.scope3.prev[2] = prevEmissions3.scope3;
          results.combined.prev[2] = prevEmissions3.scope1 + prevEmissions3.scope2 + prevEmissions3.scope3;
          results.intensity.prev[2] = results.combined.total / this.floorAreaMetric;
        }

        if (prevEmissions4) {
          results.scope1.prev[3] = prevEmissions4.scope1;
          results.scope2.prev[3] = prevEmissions4.scope2;
          results.scope3.prev[3] = prevEmissions4.scope3;
          results.combined.prev[3] = prevEmissions4.scope1 + prevEmissions4.scope2 + prevEmissions4.scope3;
          results.intensity.prev[3] = results.combined.total / this.floorAreaMetric;
        }

        if (prevEmissions5) {
          results.scope1.prev[4] = prevEmissions5.scope1;
          results.scope2.prev[4] = prevEmissions5.scope2;
          results.scope3.prev[4] = prevEmissions5.scope3;
          results.combined.prev[4] = prevEmissions5.scope1 + prevEmissions5.scope2 + prevEmissions5.scope3;
          results.intensity.prev[4] = results.combined.total / this.floorAreaMetric;
        }
      }

      return results;
    },

    epcRatingsChart() {
      return {
        series: [
          {
            name: 'Ratings',
            data: Object.values(this.analytics['epc-ratings']?.results || []).map(v => v),
            step: 1
          }
        ],
        options: {
          chart: {
            type: 'bar'
          },
          title: this.defaultChartOptions.title,
          colors: this.defaultChartOptions.colors,
          labels: Object.keys(this.analytics['epc-ratings']?.results || []).map(v => v),
          legend: {
            enabled: false
          },
          yaxis: {
            labels: {
              formatter: v => v.toFixed(0)
            }
          }
        }
      };
    },
    elecBenchmarkChart() {
      const mainParams = {
        chart: {
          type: 'bar',
          stacked: true,
          toolbar: {
            show: false
          }
        },
        colors: [
          function ({ seriesIndex }) {
            return seriesIndex === 2 ? '#1F303D' : '#82b54b';
          },
          '#fcb119'
        ],
        xaxis: {
          categories: ['REEB', this.type === 'asset' ? 'Asset' : 'Portfolio'],
          title: {
            text: 'Elec kWh/m²'
          },
          labels: {
            style: {
              fontFamily: 'Inter, Arial, sans-serif',
              fontWeight: 600
            }
          }
        },
        yaxis: {
          labels: {
            style: {
              fontFamily: 'Inter, Arial, sans-serif',
              fontWeight: 600
            }
          }
        },
        legend: {
          show: false
        },
        dataLabels: {
          enabled: false
        },
        plotOptions: {
          bar: {
            horizontal: true
          }
        }
      };

      const elecConsumption = this.analytics['elec-consumption']?.results?.totalConsumption / this.floorAreaMetric;

      return {
        // elec: {
        //   options: mainParams,
        //   series: [{ data: this.analytics['elec-consumption']?.results?.monthlyConsumption.map(m => [new Date(m.period), m.consumption]) }]
        // },
        options: {
          ...mainParams
        },
        series: [
          {
            name: 'Typical',
            group: 'budget',
            data: [134]
          },
          {
            name: 'Good',
            group: 'budget',
            data: [50]
          },
          {
            name: '',
            group: 'budget2',
            data: [0, elecConsumption]
          }
        ]
      };
    },
    combinedBenchmarkChart() {
      const mainParams = {
        chart: {
          type: 'bar',
          stacked: true,
          toolbar: {
            show: false
          }
        },
        colors: [
          function ({ seriesIndex }) {
            if (seriesIndex === 0) return '#1B98E0';
            if (seriesIndex === 1) return '#945ab9';
            if (seriesIndex === 2) return '#1F303D';
            return seriesIndex === 4 ? '#1F303D' : '#82b54b';
          }
        ],
        xaxis: {
          categories: ['LETI', 'CRREM', this.type === 'asset' ? 'Asset' : 'Portfolio'],
          title: {
            text: 'Elec & Gas kWh/m²'
          },
          labels: {
            style: {
              fontFamily: 'Inter, Arial, sans-serif',
              fontWeight: 600
            }
          }
        },
        yaxis: {
          labels: {
            style: {
              fontFamily: 'Inter, Arial, sans-serif',
              fontWeight: 600
            }
          }
        },
        legend: {
          show: false
        },
        dataLabels: {
          enabled: false
        },
        plotOptions: {
          bar: {
            horizontal: true
          }
        }
      };

      const elecConsumption = this.analytics['elec-consumption']?.results?.totalConsumption / this.floorAreaMetric;
      const gasConsumption = this.analytics['gas-consumption']?.results?.totalConsumption / this.floorAreaMetric;
      const totalConsumption = elecConsumption + gasConsumption;

      const leti = 55;
      const crrem = 201.7;
      // const minRed = 50;

      // const crremRed = totalConsumption > crrem ? totalConsumption : minRed;
      // const letiRed = totalConsumption > leti ? totalConsumption + 51.7 : minRed + (crrem - leti);

      return {
        options: {
          ...mainParams
        },
        series: [
          {
            name: 'Typical',
            group: 'benchmark',
            data: [leti]
          },
          {
            name: 'Typical',
            group: 'benchmark2',
            data: [0, crrem]
          },
          {
            name: '',
            group: 'asset',
            data: [0, 0, totalConsumption]
          }
        ]
      };
    },
    emissionsChartLine() {
      const mainParams = {
        chart: {
          type: 'line',
          toolbar: {
            show: false
          }
        },
        colors: ['#65C198', '#F25F5C', '#FFE066', '#1F303D', '#aaa'],
        xaxis: {
          type: 'datetime',
          labels: {
            style: {
              fontFamily: 'Inter, Arial, sans-serif',
              fontWeight: 600
            }
          }
        },
        yaxis: {
          title: {
            text: 'kg CO2e/m²'
          },
          decimalsInFloat: 0,
          labels: {
            style: {
              fontFamily: 'Inter, Arial, sans-serif',
              fontWeight: 600
            }
          }
        },
        legend: {
          position: 'bottom'
        },
        fill: {
          opacity: 1
        },
        stroke: {
          width: 3,
          colors: undefined,
          dashArray: [0, 0, 0, 0, 6]
        },
        dataLabels: {
          enabled: false
        }
      };

      return {
        // elec: {
        //   options: mainParams,
        //   series: [{ data: this.analytics['elec-consumption']?.results?.monthlyConsumption.map(m => [new Date(m.period), m.consumption]) }]
        // },
        options: {
          ...mainParams
        },
        series: [
          {
            name: 'Scope 1',
            data: [
              [moment(this.options.startDate).format('YYYY'), this.emissionSummary.scope1.total / this.floorAreaMetric],
              [moment(this.options.startDate).subtract(1, 'year').format('YYYY'), this.emissionSummary.scope1.prev[0] / this.floorAreaMetric],
              [moment(this.options.startDate).subtract(2, 'year').format('YYYY'), this.emissionSummary.scope1.prev[1] / this.floorAreaMetric],
              [moment(this.options.startDate).subtract(3, 'year').format('YYYY'), this.emissionSummary.scope1.prev[2] / this.floorAreaMetric],
              [moment(this.options.startDate).subtract(4, 'year').format('YYYY'), this.emissionSummary.scope1.prev[3] / this.floorAreaMetric],
              [moment(this.options.startDate).subtract(5, 'year').format('YYYY'), this.emissionSummary.scope1.prev[4] / this.floorAreaMetric]
            ]
          },
          {
            name: 'Scope 2',
            data: [
              [moment(this.options.startDate).format('YYYY'), this.emissionSummary.scope2.total / this.floorAreaMetric],
              [moment(this.options.startDate).subtract(1, 'year').format('YYYY'), this.emissionSummary.scope2.prev[0] / this.floorAreaMetric],
              [moment(this.options.startDate).subtract(2, 'year').format('YYYY'), this.emissionSummary.scope2.prev[1] / this.floorAreaMetric],
              [moment(this.options.startDate).subtract(3, 'year').format('YYYY'), this.emissionSummary.scope2.prev[2] / this.floorAreaMetric],
              [moment(this.options.startDate).subtract(4, 'year').format('YYYY'), this.emissionSummary.scope2.prev[3] / this.floorAreaMetric],
              [moment(this.options.startDate).subtract(5, 'year').format('YYYY'), this.emissionSummary.scope2.prev[4] / this.floorAreaMetric]
            ]
          },
          {
            name: 'Scope 3',
            data: [
              [moment(this.options.startDate).format('YYYY'), this.emissionSummary.scope3.total / this.floorAreaMetric],
              [moment(this.options.startDate).subtract(1, 'year').format('YYYY'), this.emissionSummary.scope3.prev[0] / this.floorAreaMetric],
              [moment(this.options.startDate).subtract(2, 'year').format('YYYY'), this.emissionSummary.scope3.prev[1] / this.floorAreaMetric],
              [moment(this.options.startDate).subtract(3, 'year').format('YYYY'), this.emissionSummary.scope3.prev[2] / this.floorAreaMetric],
              [moment(this.options.startDate).subtract(4, 'year').format('YYYY'), this.emissionSummary.scope3.prev[3] / this.floorAreaMetric],
              [moment(this.options.startDate).subtract(5, 'year').format('YYYY'), this.emissionSummary.scope3.prev[4] / this.floorAreaMetric]
            ]
          },
          {
            name: 'Total',
            data: [
              [moment(this.options.startDate).format('YYYY'), this.emissionSummary.combined.total / this.floorAreaMetric],
              [moment(this.options.startDate).subtract(1, 'year').format('YYYY'), this.emissionSummary.combined.prev[0] / this.floorAreaMetric],
              [moment(this.options.startDate).subtract(2, 'year').format('YYYY'), this.emissionSummary.combined.prev[1] / this.floorAreaMetric],
              [moment(this.options.startDate).subtract(3, 'year').format('YYYY'), this.emissionSummary.combined.prev[2] / this.floorAreaMetric],
              [moment(this.options.startDate).subtract(4, 'year').format('YYYY'), this.emissionSummary.combined.prev[3] / this.floorAreaMetric],
              [moment(this.options.startDate).subtract(5, 'year').format('YYYY'), this.emissionSummary.combined.prev[4] / this.floorAreaMetric]
            ]
          },
          {
            name: 'CRREM 1.5°',
            data: [
              [new Date('2022'), 54.8],
              [new Date('2021'), 55.9],
              [new Date('2020'), 57.1],
              [new Date('2019'), 58.6],
              [new Date('2018'), 60.5]
            ]
          }
        ]
      };
    },
    benchmarkConsumptionLineChart() {
      const totalConsumptionSeries = (this.analytics['elec-consumption']?.results?.monthlyConsumption || []).map(m => {
        const gas = (this.analytics['gas-consumption']?.results?.monthlyConsumption || []).find(g => g.period === m.period)?.consumption || 0;
        return [new Date(m.period), (m.consumption + gas) / this.floorAreaMetric];
      });

      const max = Math.max(...totalConsumptionSeries.map(s => s[1]));

      const elecSeries = (this.analytics['elec-consumption']?.results?.monthlyConsumption || []).map(m => [
        new Date(m.period),
        m.consumption / this.floorAreaMetric
      ]);

      const gasSeries = (this.analytics['gas-consumption']?.results?.monthlyConsumption || []).map(m => [
        new Date(m.period),
        m.consumption / this.floorAreaMetric
      ]);

      const mainParams = {
        chart: {
          type: 'line',
          toolbar: {
            show: false
          }
        },
        colors: ['#727272', '#fcb119', '#e04f1b'],
        xaxis: {
          type: 'datetime',
          labels: {
            style: {
              fontFamily: 'Inter, Arial, sans-serif',
              fontWeight: 600
            }
          },
          forceNiceScale: true
        },
        yaxis: {
          title: {
            text: 'kWh/m²'
          },
          decimalsInFloat: 0,
          labels: {
            style: {
              fontFamily: 'Inter, Arial, sans-serif',
              fontWeight: 600
            }
          },
          max: max < 20 ? 20 : Math.ceil(max + 5)
        },
        legend: {
          position: 'bottom'
        },
        fill: {
          opacity: 1
        },
        stroke: {
          width: 3,
          colors: undefined
        },
        dataLabels: {
          enabled: false
        },
        annotations: {
          yaxis: [
            {
              y: 124 / 12,
              strokeDashArray: 6,
              stroke: 3,
              borderColor: '#82b54b',
              borderWidth: 2,
              label: {
                borderColor: 'transparent',
                style: {
                  color: '#82b54bbb',
                  background: 'transparent',
                  fontWeight: 'bold'
                },
                text: 'REEB - Office Air Conditioned (Good)'
              }
            },
            {
              y: 174 / 12,
              strokeDashArray: 6,
              stroke: 3,
              borderColor: '#fcb119',
              borderWidth: 2,
              label: {
                borderColor: 'transparent',
                style: {
                  color: '#fcb119bb',
                  background: 'transparent',
                  fontWeight: 'bold'
                },
                text: 'REEB - Office Air Conditioned (Typical)'
              }
            },
            {
              y: 55 / 12,
              strokeDashArray: 6,
              stroke: 3,
              borderColor: '#1B98E0',
              borderWidth: 2,
              label: {
                borderColor: 'transparent',
                style: {
                  color: '#1B98E0BB',
                  background: 'transparent',
                  fontWeight: 'bold'
                },
                text: 'LETI - Commercial Offices'
              }
            },
            {
              y: 201.7 / 12,
              strokeDashArray: 6,
              stroke: 3,
              borderColor: '#945ab9',
              borderWidth: 2,
              label: {
                borderColor: 'transparent',
                style: {
                  color: '#945ab9bb',
                  background: 'transparent',
                  fontWeight: 'bold'
                },
                text: 'CRREM - Office 1.5°',
                offsetY: -5
              }
            }
          ]
        }
      };

      return {
        // elec: {
        //   options: mainParams,
        //   series: [{ data: this.analytics['elec-consumption']?.results?.monthlyConsumption.map(m => [new Date(m.period), m.consumption]) }]
        // },
        options: {
          ...mainParams
        },
        series: [
          { name: 'Total', data: totalConsumptionSeries },
          { name: 'Elec', data: elecSeries },
          { name: 'Gas', data: gasSeries }
        ]
      };
    },
    consumptionCharts() {
      const totalWater = this.analytics['water-consumption']?.results?.totalConsumption / this.floorAreaMetric;
      const totalSolar = this.analytics['solar-consumption']?.results?.totalConsumption / this.floorAreaMetric;

      const lineParams = {
        chart: {
          type: 'line',
          toolbar: {
            show: false
          }
        },
        colors: this.defaultChartOptions.colors,
        xaxis: {
          type: 'datetime',
          labels: {
            style: {
              fontFamily: 'Inter, Arial, sans-serif',
              fontWeight: 600
            }
          }
        },
        yaxis: {
          title: {
            text: 'kWh/m²'
          },
          decimalsInFloat: 0,
          labels: {
            style: {
              fontFamily: 'Inter, Arial, sans-serif',
              fontWeight: 600
            }
          }
        },
        legend: {
          position: 'bottom'
        },
        fill: {
          opacity: 1
        },
        stroke: {
          width: 3,
          colors: undefined
        },
        dataLabels: {
          enabled: false
        }
      };

      return {
        solar: {
          options: {
            ...lineParams,
            colors: ['#82b54b'],
            yaxis: {
              ...lineParams.yaxis,
              title: {
                text: 'kWh/m²'
              },
              decimalsInFloat: totalSolar < 20 ? 2 : 0
            }
          },
          series: [
            {
              name: 'Solar',
              data: (this.analytics['solar-consumption']?.results?.monthlyConsumption || []).map(m => [
                new Date(m.period),
                m.consumption / this.floorAreaMetric
              ])
            }
          ]
        },
        water: {
          options: {
            ...lineParams,
            colors: ['#3c90df'],
            yaxis: {
              ...lineParams.yaxis,
              title: {
                text: 'm³/m²'
              },
              decimalsInFloat: totalWater < 20 ? 2 : 0
            }
          },
          series: [
            {
              name: 'Water',
              data: (this.analytics['water-consumption']?.results?.monthlyConsumption || []).map(m => [
                new Date(m.period),
                m.consumption / this.floorAreaMetric
              ])
            }
          ]
        }
      };
    },
    scopeBreakdownChart() {
      // const scope1 = this.emissionScopeBreakdown['1'];
      // const scope2 = this.emissionScopeBreakdown['2'];
      // const scope3 = this.emissionScopeBreakdown['3'];

      return {
        series: [this.emissionSummary.scope1.total, this.emissionSummary.scope2.total, this.emissionSummary.scope3.total],
        options: {
          chart: {
            type: 'pie'
          },
          title: {
            ...this.defaultChartOptions.title,
            text: 'All Scopes'
          },
          colors: this.defaultChartOptions.colors,
          labels: ['Scope 1', 'Scope 2', 'Scope 3'],
          legend: {
            position: 'bottom'
          },
          fill: {
            opacity: 1
          },
          stroke: {
            width: 1,
            colors: undefined
          },
          dataLabels: {
            enabled: false
          }
        }
      };
    },
    emissionsBreakdownChart() {
      const values = (this.analytics['emissions-summary']?.results?.breakdown || []).map(item => item.total);

      const isShowMeterUser = this.analytics['emissions-summary']?.results?.breakdown.some(item => item.meterUser === 'tenant');

      return {
        series: values,
        options: {
          chart: {
            type: 'pie'
          },
          title: {
            ...this.defaultChartOptions.title,
            text: 'Scope 3 Breakdown'
          },
          colors: this.defaultChartOptions.colors,
          labels: (this.analytics['emissions-summary']?.results?.breakdown || []).map(
            item => `${item.category}${isShowMeterUser ? ` (${capitalize(item.meterUser)})` : ''}`
          ),
          legend: {
            position: 'bottom'
          },
          fill: {
            opacity: 1
          },
          stroke: {
            width: 1,
            colors: undefined
          },
          dataLabels: {
            enabled: false
          }
        }
      };
    },
    defaultChartOptions() {
      return {
        title: {
          text: '',
          align: 'center',
          style: {
            fontFamily: 'Inter, Arial, sans-serif',
            color: '#6c757d'
          }
        },
        xAxis: {
          type: 'datetime',
          dateTimeLabelFormats: {
            month: '%b',
            year: '%b'
          },
          title: {
            text: 'Date'
          }
        },
        yAxis: {
          title: {
            text: 'Label'
          },
          min: 0
        },
        tooltip: {
          headerFormat: '<b>{series.name}</b><br>',
          pointFormat: '{point.name}: {point.y}'
        },
        plotOptions: {
          series: {
            marker: {
              enabled: true
            }
          }
        },
        colors: ['#65C198', '#1F303D', '#F25F5C', '#FFE066', '#1B98E0', '#727272', '#db6edc', '#945ab9', '#25714e', '#941d1c', '#977911', '#045889'],
        series: [],
        responsive: {
          rules: [
            {
              condition: {
                maxWidth: 500
              },
              chartOptions: {
                plotOptions: {
                  series: {
                    marker: {
                      radius: 2.5
                    }
                  }
                }
              }
            }
          ]
        },
        credits: {
          enabled: false
        }
      };
    },
    epcCert() {
      if (this.type === 'assetGroup') return null;

      const epcCerts = (this.asset.documents || []).filter(
        d => d.type === 'epb' && d.status === 'active' && d.certificate && d.certificate.type === 'CEPC'
      );

      if (epcCerts.length > 0) {
        return epcCerts[0];
      } else {
        return null;
      }
    }
  },
  async mounted() {
    const assetSource = this.report.metadata.sourceIds.find(id => id.type === 'asset');
    if (!assetSource) this.errorRedirect('Missing Asset ID in Report Metadata');

    const assetId = assetSource._id;

    if (!assetId) this.errorRedirect('Missing Asset ID or Asset Group ID');

    this.type = 'asset';
    await this.getAsset({ id: assetId });

    this.period.startDate = moment(this.report.metadata.startDate).startOf('month');
    this.period.endDate = moment(this.report.metadata.endDate).endOf('month');
    this.period.prevStartDate = moment(this.period.startDate).subtract(1, 'year');
    this.period.prevEndDate = moment(this.period.endDate).subtract(1, 'year');

    this.getBenchmarks();
    await this.getCompany({ id: this.type === 'asset' ? this.asset.companyId : this.assetGroup.companyId });

    const params = {
      id: 'asset-emission',
      startDate: moment(this.period.startDate).format('YYYY-MM-DD'),
      endDate: moment(this.period.endDate).format('YYYY-MM-DD'),
      sourceData: 'invoice',
      dateBreakdown: 'monthly',
      assetId: this.asset._id,
      companyId: this.company._id
    };

    this.getAnalytics({
      id: 'emissions-summary',
      params: params
    });

    this.getAnalytics({
      id: 'emissions-summary-prev',
      params: {
        ...params,
        startDate: moment(this.period.prevStartDate).format('YYYY-MM-DD'),
        endDate: moment(this.period.prevEndDate).format('YYYY-MM-DD')
      }
    });

    this.getAnalytics({
      id: 'elec-consumption',
      params: {
        ...params,
        id: 'asset-consumption'
      }
    });

    this.getAnalytics({
      id: 'elec-consumption-prev',
      params: {
        ...params,
        id: 'asset-consumption',
        startDate: moment(this.period.prevStartDate).format('YYYY-MM-DD'),
        endDate: moment(this.period.prevEndDate).format('YYYY-MM-DD')
      }
    });

    this.getAnalytics({
      id: 'gas-consumption',
      params: {
        ...params,
        accountType: 'gas',
        id: 'asset-consumption'
      }
    });

    this.getAnalytics({
      id: 'gas-consumption-prev',
      params: {
        ...params,
        accountType: 'gas',
        id: 'asset-consumption',
        startDate: moment(this.period.prevStartDate).format('YYYY-MM-DD'),
        endDate: moment(this.period.prevEndDate).format('YYYY-MM-DD')
      }
    });

    this.getAnalytics({
      id: 'water-consumption',
      params: {
        ...params,
        accountType: 'water',
        id: 'asset-consumption'
      }
    });

    this.getAnalytics({
      id: 'water-consumption-prev',
      params: {
        ...params,
        accountType: 'water',
        id: 'asset-consumption',
        startDate: moment(this.period.prevStartDate).format('YYYY-MM-DD'),
        endDate: moment(this.period.prevEndDate).format('YYYY-MM-DD')
      }
    });

    this.getAnalytics({
      id: 'solar-consumption',
      params: {
        ...params,
        accountType: 'solar',
        sourceData: 'consumption',
        id: 'asset-consumption'
      }
    });

    this.getAnalytics({
      id: 'solar-consumption-prev',
      params: {
        ...params,
        accountType: 'solar',
        sourceData: 'consumption',
        id: 'asset-consumption',
        startDate: moment(this.period.prevStartDate).format('YYYY-MM-DD'),
        endDate: moment(this.period.prevEndDate).format('YYYY-MM-DD')
      }
    });

    this.getAnalytics({
      id: 'contract-renewable',
      params: {
        id: 'asset-contract-renewable',
        assetId: this.asset._id,
        companyId: this.company._id
      }
    });

    this.getAnalytics({
      id: 'asset-emissions',
      params: {
        ...params,
        id: 'asset-emission'
      }
    });

    this.getAnalytics({
      id: 'asset-emissions-prev',
      params: {
        ...params,
        id: 'asset-emission',
        startDate: moment(this.period.prevStartDate).format('YYYY-MM-DD'),
        endDate: moment(this.period.prevEndDate).format('YYYY-MM-DD')
      }
    });

    this.getAnalytics({
      id: 'asset-emissions-prev2',
      params: {
        ...params,
        id: 'asset-emission',
        startDate: moment(this.period.prevStartDate).subtract(1, 'year').format('YYYY-MM-DD'),
        endDate: moment(this.period.prevEndDate).subtract(1, 'year').format('YYYY-MM-DD')
      }
    });

    this.getAnalytics({
      id: 'asset-emissions-prev3',
      params: {
        ...params,
        id: 'asset-emission',
        startDate: moment(this.period.prevStartDate).subtract(2, 'year').format('YYYY-MM-DD'),
        endDate: moment(this.period.prevEndDate).subtract(2, 'year').format('YYYY-MM-DD')
      }
    });

    this.getAnalytics({
      id: 'asset-emissions-prev4',
      params: {
        ...params,
        id: 'asset-emission',
        startDate: moment(this.period.prevStartDate).subtract(3, 'year').format('YYYY-MM-DD'),
        endDate: moment(this.period.prevEndDate).subtract(3, 'year').format('YYYY-MM-DD')
      }
    });

    this.getAnalytics({
      id: 'asset-emissions-prev5',
      params: {
        ...params,
        id: 'asset-emission',
        startDate: moment(this.period.prevStartDate).subtract(4, 'year').format('YYYY-MM-DD'),
        endDate: moment(this.period.prevEndDate).subtract(4, 'year').format('YYYY-MM-DD')
      }
    });

    await this.getAnalytics({
      id: 'epc-ratings',
      params: {
        id: 'epc-ratings',
        companyId: this.company._id,
        assetId: this.asset._id
      }
    });

    await this.getAnalytics({
      id: 'waste-consumption',
      params: {
        ...params,
        dateBreakdown: undefined,
        accountType: 'waste',
        sourceData: 'consumption',
        categoryBreakdown: true,
        id: 'asset-consumption'
      }
    });

    await this.getWasteSummary();
  },
  methods: {
    ...mapActions({
      getAsset: 'asset/get',
      getAssetAccounts: 'asset/accounts',
      getAssetGroup: 'assetGroup/get',
      getCompany: 'company/get',
      getReport: 'report/get',
      getBenchmarks: 'benchmark/list',
      getAnalytics: 'analytics/getAnalytics',
      getAssetGroupAssets: 'assetGroup/listAssets'
    }),
    errorRedirect(reason) {
      this.$router.push({ name: 'machine-error', query: { errorCode: 500, errorData: { message: reason } } });
    },
    async getWasteSummary() {
      if (!this.analytics['waste-consumption']?.results?.categoryBreakdown) return null;

      // const landfillCategories = ['general waste', 'general bulky'];
      const efwCategories = ['general waste', 'general bulky'];
      const recycleCategories = ['mixed recyclables', 'weee', 'batteries', 'confi. shredding', 'glass'];
      const compostCategories = ['food'];

      const byCategory = this.analytics['waste-consumption'].results.categoryBreakdown;

      const total = byCategory.reduce((sum, cat) => sum + cat.value, 0);

      const efw = byCategory.filter(c => efwCategories.includes(c.name.toLowerCase())).reduce((sum, cat) => sum + cat.value, 0);
      const efwPct = total > 0 ? efw / total : 0;

      const recycled = byCategory.filter(c => recycleCategories.includes(c.name.toLowerCase())).reduce((sum, cat) => sum + cat.value, 0);
      const recycledPct = total > 0 ? recycled / total : 0;

      const compost = byCategory.filter(c => compostCategories.includes(c.name.toLowerCase())).reduce((sum, cat) => sum + cat.value, 0);
      const compostPct = total > 0 ? compost / total : 0;

      this.consumption.waste = {
        total: total.toFixed(0),
        recycled: recycled.toFixed(0),
        recycledPct: (recycledPct * 100).toFixed(0),
        recycledPctWidth: recycledPct <= 0.03 ? 3 : 174 * recycledPct,
        efw: efw.toFixed(0),
        efwPct: (efwPct * 100).toFixed(0),
        efwPctWidth: efwPct <= 0.03 ? 3 : 174 * efwPct,
        compost: compost.toFixed(0),
        compostPct: (compostPct * 100).toFixed(0),
        compostPctWidth: compostPct <= 0.03 ? 3 : 174 * compostPct
      };
    }
  }
};
</script>
<style lang="scss" scoped>
.benchmark-logo {
  width: 90px;
  height: 40px;
  object-fit: contain;
}

.consumption-box {
  background-color: #f1f1f1;
  border-radius: 6px;
  padding: 15px 20px;

  .border-bottom {
    border-bottom: 2px solid #cfd5da !important;
  }
}

.consumption-box--trimmed {
  padding-bottom: 0px;
}

.divider-bottom {
  border-bottom: 3px solid #f4f4f4;
}

.divider-right {
  border-right: 3px solid #f4f4f4;
}

.bars {
  display: flex;
  align-items: center;

  color: #fdfdfd;
  font-weight: 700;
  font-size: 13px;

  margin-bottom: 8px;
}

.bars-bar {
  position: relative;
  display: flex;
  align-items: center;
  background-color: #bbb;

  height: 20px;
  width: 115px;
  white-space: nowrap;

  border-radius: 5px;

  &.success {
    background-color: #82b54b;
  }

  &.brown {
    background-color: #6f411b;
  }
}

.bars-text {
  padding: 0 10px;
  width: auto;
  color: #1f303d;
  text-align: right;
  white-space: nowrap;
}

.asset-epc {
  display: block;
}

.epc {
  display: flex;
  align-items: center;

  color: #fdfdfd;
  font-weight: 700;
  font-size: 13px;

  margin-bottom: 6px;
}

.epc-score {
  display: flex;
  align-items: center;

  height: 18px;
  min-width: 30px;

  padding: 1px 10px 0;
  background: rgba(255, 255, 255, 0.2);

  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.epc-pointer {
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 1px 5px 0;
  min-width: 30%;
  height: 18px;
  white-space: nowrap;

  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;

  small {
    color: #ffffff85;
  }
}

.epc.selected .epc-score {
  height: 24px;
}

.epc.selected .epc-pointer {
  width: 80%;
  height: 24px;
  color: #ffffff;
}

.epc.counter .epc-score {
  height: 24px;
}

.epc.counter .epc-pointer {
  width: 60%;
  height: 24px;
  color: #ffffff;
}

$epc-colors: (
  a: #00c781,
  b: #19b459,
  c: #8dce46,
  d: #ffd500,
  e: #fcaa65,
  f: #ef8023,
  g: #e9153b
);

@each $name, $hex in $epc-colors {
  .epc-pointer.#{$name} {
    background-color: $hex;
  }
  .epc-score.#{$name} {
    background-color: rgba($hex, 0.75);
  }
}

.text-brown {
  color: #6f411b;
}
</style>
