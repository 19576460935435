<template>
  <div class="report-page" :class="{ 'report-page--landscape': landscape }">
    <div v-if="title" class="report-bg"></div>
    <div v-if="!noHeader" class="report-header">
      <div class="report-logo report-logo--left">
        <img :src="$auth.company.settings.logoUrl" alt="Company Logo" />
      </div>
      <div v-if="$auth.company?.settings?.logoUrl" class="report-logo report-logo--right">
        <Logo />
      </div>
    </div>
    <div class="report-body" :class="{ 'report-body--no-header': noHeader, 'report-body--no-footer': noFooter }">
      <div v-if="title" class="report-heading">
        <div>
          <h3 class="text-primary mb-1">{{ subTitle }}</h3>
          <h1 class="mb-2">{{ title }}</h1>
          <h5>{{ description }}</h5>
          <div class="report-title--content">
            <slot name="title"></slot>
          </div>
        </div>
      </div>
      <slot></slot>
    </div>
    <div v-if="!noFooter && !title" class="report-footer">
      <div class="report-footer--inner">
        <div class="text-left">{{ currentDate }}</div>
        <div class="flex-grow-1 text-center">
          <span v-if="!noCopyright">&copy; {{ currentYear }} Etainabl Limited | 12-16 Addiscombe Rd, Croydon, CR0 1XT</span>
        </div>
        <div class="text-right">{{ currentPage }} / {{ pages().length }}</div>
      </div>
    </div>
  </div>
</template>
<script>
import Logo from '@/components/Logo';
import moment from 'moment';

export default {
  name: 'Page',
  components: {
    Logo
  },
  props: {
    noHeader: {
      type: Boolean,
      default: false
    },
    noFooter: {
      type: Boolean,
      default: false
    },
    noFooterText: {
      type: Boolean,
      default: false
    },
    noCopyright: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: null
    },
    subTitle: {
      type: String,
      default: null
    },
    description: {
      type: String,
      default: null
    },
    landscape: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    currentDate() {
      return moment().format('DD MMMM YYYY');
    },
    currentYear() {
      return moment().format('YYYY');
    },
    currentPage() {
      const pages = this.pages();
      if (!pages) return '';

      const index = this.landscape ? pages.indexOf(this.$parent._uid) : pages.indexOf(this._uid);

      return index === -1 ? '' : index + 1;
    }
  },
  mounted() {
    console.log('Page', this);
  },
  inject: ['pages']
};
</script>
<style lang="scss" scoped>
$marginY: 20.4mm;
$marginX: 20.4mm;
$header: 30mm;
$footer: $header;

@page {
  size: a4;
}

.etn-report {
  font-size: 16px;

  table th,
  table td {
    padding: 0.3rem 0.5rem;
    height: 20px;
  }

  .logo {
    object-fit: contain;
    object-position: right center;
    height: 65px;
    width: 100%;
  }

  .divider-bottom {
    border-bottom: 3px solid #f4f4f4;
  }

  .divider-right {
    border-right: 3px solid #f4f4f4;
  }
}

.report-page {
  position: relative;
  overflow: hidden;
  background: #fff;
  box-shadow: 5px 5px 10px 2px #0000003b;
  margin: 0 auto;
  page-break-after: always;
  height: 1584px;
  width: 1120px;
  font-size: 16px;

  margin-bottom: 30px;
}

.is-preview .report-page {
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  margin: 0 0 30px 0;
}

.report-page--landscape {
  height: 1120px;
  width: 1584px;
}

.report-logo {
  &--left img,
  &--left svg {
    object-position: left;
    object-fit: contain;
    width: 250px;
    height: 150px;
  }

  &--right img,
  &--right svg {
    object-position: right;
    object-fit: contain;
    width: 200px;
    height: 60px;
  }
}

.report-header {
  display: flex;
  position: absolute;
  top: 0px;
  height: $header;
  width: 100%;
  padding: $marginY $marginX 0 $marginX;
  justify-content: space-between;
}

.report-body {
  position: absolute;
  top: $header + $marginY;
  bottom: $footer + $marginY;
  width: 100%;
  padding: 0 $marginX;
}

.report-body--no-header {
  top: $marginY;
}

.report-footer {
  position: absolute;
  bottom: 0px;
  height: $footer;
  width: 100%;
  padding: 0 $marginX $marginY $marginX;

  border-top: 1px solid #fff;

  font-size: 14px;
  font-weight: 600;

  &--inner {
    padding-top: 10px;
    display: flex;
    align-items: end;
    justify-content: center;

    border-top: 2px solid #f0f3f8;
  }
}

.report-bg {
  background-color: #65c198;
  left: -150px;
  right: -50px;
  bottom: -200px;
  position: absolute;
  top: 75%;
  transform: rotate(15deg);
}

.report-heading {
  display: flex;
  height: 100%;
  align-items: center;
  padding-bottom: 400px;
}

.report-title--content {
  padding-top: 50px;
}

h1 {
  font-size: 2.75rem;
}

h2 {
  font-size: 2.3rem;
}

h3 {
  font-size: 1.9rem;
}

h4 {
  font-size: 1.5rem;
}

h5 {
  font-size: 1.3rem;
}

p {
  font-size: 16px;
}

@media print {
  body {
    -webkit-print-color-adjust: exact !important;
  }

  .report-page {
    margin: 0;
    box-shadow: none;
  }
  .report-container {
    padding: 0;
    background: #fff;
  }
}
</style>
