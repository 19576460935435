var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('Page', _vm._b({
    attrs: {
      "landscape": ""
    }
  }, 'Page', Object.assign({}, _vm.$props, _vm.$attrs), false), [_vm._t("default")], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }