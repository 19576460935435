var render = function () {
  var _ref, _vm$asset$images, _vm$epcCert, _vm$epcCert$certifica, _vm$epcCert2, _vm$epcCert2$certific, _vm$epcCert3, _vm$epcCert3$certific, _vm$epcCert4, _vm$epcCert4$certific, _vm$epcCert5, _vm$epcCert5$certific, _vm$epcCert6, _vm$epcCert6$certific, _vm$epcCert7, _vm$epcCert7$certific, _vm$epcCert8, _vm$epcCert8$certific, _vm$epcCert9, _vm$epcCert9$certific, _vm$epcCert10, _vm$epcCert10$certifi, _vm$epcCert11, _vm$epcCert11$certifi, _vm$epcCert12, _vm$epcCert12$certifi, _vm$epcCert13, _vm$epcCert13$certifi, _vm$epcCert14, _vm$epcCert14$certifi, _vm$analytics$elecCo, _vm$analytics$elecCo$, _vm$analytics$waterC, _vm$analytics$waterC$, _vm$analytics$solarC, _vm$analytics$solarC$, _vm$analytics$elecCo2, _vm$analytics$elecCo3, _vm$analytics$gasCon, _vm$analytics$solarC2, _vm$analytics$solarC3;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('Pages', {
    attrs: {
      "title": "Asset Summary"
    }
  }, [_c('PageLandscape', {
    attrs: {
      "no-header": "",
      "no-footer": ""
    }
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-3 pr-4"
  }, [_vm.type === 'asset' ? _c('div', {
    staticClass: "mb-3"
  }, [_c('div', {
    staticClass: "mb-2"
  }, [_c('h6', {
    staticClass: "text-muted mb-0"
  }, [_vm._v(_vm._s(_vm._f("date")(_vm.period.startDate, 'MMM YYYY')) + " to " + _vm._s(_vm._f("date")(_vm.period.endDate, 'MMM YYYY')))]), _c('h3', {
    staticClass: "text-primary mb-0"
  }, [_vm._v(_vm._s(_vm.asset.siteName))]), _c('h6', {
    staticClass: "mb-0"
  }, [_vm._v(_vm._s(_vm.asset.addressString))])]), _c('div', {
    staticClass: "asset-image mb-2"
  }, [_c('img', {
    staticClass: "img-fluid",
    attrs: {
      "src": (_ref = ((_vm$asset$images = _vm.asset.images) === null || _vm$asset$images === void 0 ? void 0 : _vm$asset$images.find(function (i) {
        return i.isPrimary;
      })) || _vm.asset.images[0]) === null || _ref === void 0 ? void 0 : _ref.url,
      "alt": "Asset Image"
    }
  })]), _c('div', {
    staticClass: "asset-info"
  }, [_c('PropertyListItem', {
    staticClass: "mb-2 font-w600",
    attrs: {
      "col-size": "col-lg-4",
      "title": "Floor Area"
    }
  }, [_vm._v(" " + _vm._s(_vm._f("numberFormat")(_vm.floorAreaMetric, 0)) + " m"), _c('sup', [_vm._v("2")]), _vm.asset.floorAreaUnit === 'imperial' ? _c('span', [_vm._v(" (" + _vm._s(_vm._f("numberFormat")(_vm.asset.floorArea, 0)) + " " + _vm._s(_vm._f("floorAreaUnit")(_vm.asset.floorAreaUnit)) + ")")]) : _vm._e()])], 1)]) : _c('div', {
    staticClass: "mb-3"
  }, [_c('div', {
    staticClass: "mb-2"
  }, [_c('h6', {
    staticClass: "text-muted mb-0"
  }, [_vm._v(_vm._s(_vm._f("date")(_vm.period.startDate, 'MMM YYYY')) + " to " + _vm._s(_vm._f("date")(_vm.period.endDate, 'MMM YYYY')))]), _c('h3', {
    staticClass: "text-primary mb-0"
  }, [_vm._v(_vm._s(_vm.assetGroup.name))])]), _c('div', {
    staticClass: "asset-info"
  }, [_c('PropertyListItem', {
    staticClass: "font-w600",
    attrs: {
      "col-size": "col-lg-6",
      "title": "Assets"
    }
  }, [_vm._v(" " + _vm._s(_vm._f("numberFormat")(_vm.assetGroupAssets.length, 0)) + " ")]), _c('PropertyListItem', {
    staticClass: "font-w600",
    attrs: {
      "col-size": "col-lg-6",
      "title": "Total Floor Area"
    }
  }, [_vm._v(" " + _vm._s(_vm._f("numberFormat")(_vm.floorAreaMetric, 0)) + " m"), _c('sup', [_vm._v("2")])]), _c('PropertyListItem', {
    staticClass: "font-w600",
    attrs: {
      "col-size": "col-lg-6",
      "title": "Avg. Floor Area"
    }
  }, [_vm._v(" " + _vm._s(_vm._f("numberFormat")(_vm.floorAreaMetric / _vm.assetGroupAssets.length, 0)) + " m"), _c('sup', [_vm._v("2")])])], 1)]), _vm.consumption.waste ? _c('div', {
    staticClass: "consumption-box mb-3"
  }, [_c('div', {
    staticClass: "d-flex justify-content-between align-items-center mb-4"
  }, [_c('div', {
    staticClass: "font-w600 text-muted font-size-h4"
  }, [_c('i', {
    staticClass: "fa fa-dumpster mr-1"
  }), _vm._v(" Waste")]), _c('div', [_c('span', {
    staticClass: "font-w600 font-size-h4 mr-2"
  }, [_vm._v(_vm._s(_vm._f("numberFormat")(_vm.consumption.waste.total, 0)) + " kg")]), _c('span', {
    staticClass: "font-w600 text-muted small"
  }, [_vm._v("Total Waste")])])]), _c('div', {
    staticClass: "font-w600 text-success mb-1"
  }, [_c('i', {
    staticClass: "fa fa-recycle fa-fw"
  }), _vm._v(" Recycled")]), _c('div', {
    staticClass: "bars"
  }, [_c('div', {
    staticClass: "bars-bar success",
    style: "width: ".concat(_vm.consumption.waste.recycledPctWidth, "px")
  }), _c('div', {
    staticClass: "bars-text"
  }, [_vm._v(_vm._s(_vm.consumption.waste.recycledPct) + "% (" + _vm._s(_vm._f("numberFormat")(_vm.consumption.waste.recycled, 0)) + " kg)")])]), _c('div', {
    staticClass: "font-w600 text-muted mb-1"
  }, [_c('i', {
    staticClass: "fa fa-dumpster fa-fw"
  }), _vm._v(" EfW")]), _c('div', {
    staticClass: "bars waste"
  }, [_c('div', {
    staticClass: "bars-bar",
    style: "width: ".concat(_vm.consumption.waste.efwPctWidth, "px")
  }), _c('div', {
    staticClass: "bars-text"
  }, [_vm._v(_vm._s(_vm.consumption.waste.efwPct) + "% (" + _vm._s(_vm._f("numberFormat")(_vm.consumption.waste.efw, 0)) + " kg)")])]), _c('div', {
    staticClass: "font-w600 text-brown mb-1"
  }, [_c('i', {
    staticClass: "fa fa-apple-core fa-fw"
  }), _vm._v(" Composted")]), _c('div', {
    staticClass: "bars waste"
  }, [_c('div', {
    staticClass: "bars-bar brown",
    style: "width: ".concat(_vm.consumption.waste.compostPctWidth, "px")
  }), _c('div', {
    staticClass: "bars-text"
  }, [_vm._v(_vm._s(_vm.consumption.waste.compostPct) + "% (" + _vm._s(_vm._f("numberFormat")(_vm.consumption.waste.compost, 0)) + " kg)")])])]) : _vm._e(), _vm.type === 'asset' ? _c('div', {
    staticClass: "consumption-box mb-3"
  }, [_c('div', {
    staticClass: "asset-epc"
  }, [_c('div', {
    staticClass: "font-w600 text-muted mb-3 font-size-h4"
  }, [_c('i', {
    staticClass: "fa fa-file-certificate mr-1"
  }), _vm._v(" EPC")]), _c('div', {
    staticClass: "epc",
    class: {
      selected: ((_vm$epcCert = _vm.epcCert) === null || _vm$epcCert === void 0 ? void 0 : (_vm$epcCert$certifica = _vm$epcCert.certificate) === null || _vm$epcCert$certifica === void 0 ? void 0 : _vm$epcCert$certifica.rating) === 'A'
    }
  }, [_c('div', {
    staticClass: "epc-score a"
  }, [_vm._v("A")]), _c('div', {
    staticClass: "epc-pointer a"
  }, [_c('small', [_vm._v("0-25")]), ((_vm$epcCert2 = _vm.epcCert) === null || _vm$epcCert2 === void 0 ? void 0 : (_vm$epcCert2$certific = _vm$epcCert2.certificate) === null || _vm$epcCert2$certific === void 0 ? void 0 : _vm$epcCert2$certific.rating) === 'A' ? _c('span', [_vm._v(_vm._s(_vm.epcCert.certificate.score) + " | " + _vm._s(_vm.epcCert.certificate.rating))]) : _vm._e()])]), _c('div', {
    staticClass: "epc",
    class: {
      selected: ((_vm$epcCert3 = _vm.epcCert) === null || _vm$epcCert3 === void 0 ? void 0 : (_vm$epcCert3$certific = _vm$epcCert3.certificate) === null || _vm$epcCert3$certific === void 0 ? void 0 : _vm$epcCert3$certific.rating) === 'B'
    }
  }, [_c('div', {
    staticClass: "epc-score b"
  }, [_vm._v("B")]), _c('div', {
    staticClass: "epc-pointer b"
  }, [_c('small', [_vm._v("26-50")]), ((_vm$epcCert4 = _vm.epcCert) === null || _vm$epcCert4 === void 0 ? void 0 : (_vm$epcCert4$certific = _vm$epcCert4.certificate) === null || _vm$epcCert4$certific === void 0 ? void 0 : _vm$epcCert4$certific.rating) === 'B' ? _c('span', [_vm._v(_vm._s(_vm.epcCert.certificate.score) + " | " + _vm._s(_vm.epcCert.certificate.rating))]) : _vm._e()])]), _c('div', {
    staticClass: "epc",
    class: {
      selected: ((_vm$epcCert5 = _vm.epcCert) === null || _vm$epcCert5 === void 0 ? void 0 : (_vm$epcCert5$certific = _vm$epcCert5.certificate) === null || _vm$epcCert5$certific === void 0 ? void 0 : _vm$epcCert5$certific.rating) === 'C'
    }
  }, [_c('div', {
    staticClass: "epc-score c"
  }, [_vm._v("C")]), _c('div', {
    staticClass: "epc-pointer c"
  }, [_c('small', [_vm._v("51-75")]), ((_vm$epcCert6 = _vm.epcCert) === null || _vm$epcCert6 === void 0 ? void 0 : (_vm$epcCert6$certific = _vm$epcCert6.certificate) === null || _vm$epcCert6$certific === void 0 ? void 0 : _vm$epcCert6$certific.rating) === 'C' ? _c('span', [_vm._v(_vm._s(_vm.epcCert.certificate.score) + " | " + _vm._s(_vm.epcCert.certificate.rating))]) : _vm._e()])]), _c('div', {
    staticClass: "epc",
    class: {
      selected: ((_vm$epcCert7 = _vm.epcCert) === null || _vm$epcCert7 === void 0 ? void 0 : (_vm$epcCert7$certific = _vm$epcCert7.certificate) === null || _vm$epcCert7$certific === void 0 ? void 0 : _vm$epcCert7$certific.rating) === 'D'
    }
  }, [_c('div', {
    staticClass: "epc-score d"
  }, [_vm._v("D")]), _c('div', {
    staticClass: "epc-pointer d"
  }, [_c('small', [_vm._v("76-100")]), ((_vm$epcCert8 = _vm.epcCert) === null || _vm$epcCert8 === void 0 ? void 0 : (_vm$epcCert8$certific = _vm$epcCert8.certificate) === null || _vm$epcCert8$certific === void 0 ? void 0 : _vm$epcCert8$certific.rating) === 'D' ? _c('span', [_vm._v(_vm._s(_vm.epcCert.certificate.score) + " | " + _vm._s(_vm.epcCert.certificate.rating))]) : _vm._e()])]), _c('div', {
    staticClass: "epc",
    class: {
      selected: ((_vm$epcCert9 = _vm.epcCert) === null || _vm$epcCert9 === void 0 ? void 0 : (_vm$epcCert9$certific = _vm$epcCert9.certificate) === null || _vm$epcCert9$certific === void 0 ? void 0 : _vm$epcCert9$certific.rating) === 'E'
    }
  }, [_c('div', {
    staticClass: "epc-score e"
  }, [_vm._v("E")]), _c('div', {
    staticClass: "epc-pointer e"
  }, [_c('small', [_vm._v("101-125")]), ((_vm$epcCert10 = _vm.epcCert) === null || _vm$epcCert10 === void 0 ? void 0 : (_vm$epcCert10$certifi = _vm$epcCert10.certificate) === null || _vm$epcCert10$certifi === void 0 ? void 0 : _vm$epcCert10$certifi.rating) === 'E' ? _c('span', [_vm._v(_vm._s(_vm.epcCert.certificate.score) + " | " + _vm._s(_vm.epcCert.certificate.rating))]) : _vm._e()])]), _c('div', {
    staticClass: "epc",
    class: {
      selected: ((_vm$epcCert11 = _vm.epcCert) === null || _vm$epcCert11 === void 0 ? void 0 : (_vm$epcCert11$certifi = _vm$epcCert11.certificate) === null || _vm$epcCert11$certifi === void 0 ? void 0 : _vm$epcCert11$certifi.rating) === 'F'
    }
  }, [_c('div', {
    staticClass: "epc-score f"
  }, [_vm._v("F")]), _c('div', {
    staticClass: "epc-pointer f"
  }, [_c('small', [_vm._v("126-150")]), ((_vm$epcCert12 = _vm.epcCert) === null || _vm$epcCert12 === void 0 ? void 0 : (_vm$epcCert12$certifi = _vm$epcCert12.certificate) === null || _vm$epcCert12$certifi === void 0 ? void 0 : _vm$epcCert12$certifi.rating) === 'F' ? _c('span', [_vm._v(_vm._s(_vm.epcCert.certificate.score) + " | " + _vm._s(_vm.epcCert.certificate.rating))]) : _vm._e()])]), _c('div', {
    staticClass: "epc",
    class: {
      selected: ((_vm$epcCert13 = _vm.epcCert) === null || _vm$epcCert13 === void 0 ? void 0 : (_vm$epcCert13$certifi = _vm$epcCert13.certificate) === null || _vm$epcCert13$certifi === void 0 ? void 0 : _vm$epcCert13$certifi.rating) === 'G'
    }
  }, [_c('div', {
    staticClass: "epc-score g"
  }, [_vm._v("G")]), _c('div', {
    staticClass: "epc-pointer g"
  }, [_c('small', [_vm._v("150+")]), ((_vm$epcCert14 = _vm.epcCert) === null || _vm$epcCert14 === void 0 ? void 0 : (_vm$epcCert14$certifi = _vm$epcCert14.certificate) === null || _vm$epcCert14$certifi === void 0 ? void 0 : _vm$epcCert14$certifi.rating) === 'G' ? _c('span', [_vm._v(_vm._s(_vm.epcCert.certificate.score) + " | " + _vm._s(_vm.epcCert.certificate.rating))]) : _vm._e()])])])]) : _vm._e(), _vm.type === 'assetGroup' ? _c('div', {
    staticClass: "consumption-box mb-3"
  }, [_c('div', {
    staticClass: "asset-epc"
  }, [_c('div', {
    staticClass: "font-w600 text-muted mb-3 font-size-h4"
  }, [_c('i', {
    staticClass: "fa fa-file-certificate mr-1"
  }), _vm._v(" EPC Count")]), _c('div', {
    staticClass: "epc counter"
  }, [_c('div', {
    staticClass: "epc-score a"
  }, [_vm._v("A")]), _c('div', {
    staticClass: "epc-pointer a"
  }, [_c('small', [_vm._v("0-25")]), _c('span', [_vm._v(_vm._s(_vm.analytics['epc-ratings'].results['A']))])])]), _c('div', {
    staticClass: "epc counter"
  }, [_c('div', {
    staticClass: "epc-score b"
  }, [_vm._v("B")]), _c('div', {
    staticClass: "epc-pointer b"
  }, [_c('small', [_vm._v("26-50")]), _c('span', [_vm._v(_vm._s(_vm.analytics['epc-ratings'].results['B']))])])]), _c('div', {
    staticClass: "epc counter"
  }, [_c('div', {
    staticClass: "epc-score c"
  }, [_vm._v("C")]), _c('div', {
    staticClass: "epc-pointer c"
  }, [_c('small', [_vm._v("51-75")]), _c('span', [_vm._v(_vm._s(_vm.analytics['epc-ratings'].results['C']))])])]), _c('div', {
    staticClass: "epc counter"
  }, [_c('div', {
    staticClass: "epc-score d"
  }, [_vm._v("D")]), _c('div', {
    staticClass: "epc-pointer d"
  }, [_c('small', [_vm._v("76-100")]), _c('span', [_vm._v(_vm._s(_vm.analytics['epc-ratings'].results['D']))])])]), _c('div', {
    staticClass: "epc counter"
  }, [_c('div', {
    staticClass: "epc-score e"
  }, [_vm._v("E")]), _c('div', {
    staticClass: "epc-pointer e"
  }, [_c('small', [_vm._v("101-125")]), _c('span', [_vm._v(_vm._s(_vm.analytics['epc-ratings'].results['E']))])])]), _c('div', {
    staticClass: "epc counter"
  }, [_c('div', {
    staticClass: "epc-score f"
  }, [_vm._v("F")]), _c('div', {
    staticClass: "epc-pointer f"
  }, [_c('small', [_vm._v("126-150")]), _c('span', [_vm._v(_vm._s(_vm.analytics['epc-ratings'].results['F']))])])]), _c('div', {
    staticClass: "epc counter"
  }, [_c('div', {
    staticClass: "epc-score g"
  }, [_vm._v("G")]), _c('div', {
    staticClass: "epc-pointer g"
  }, [_c('small', [_vm._v("150+")]), _c('span', [_vm._v(_vm._s(_vm.analytics['epc-ratings'].results['G']))])])])])]) : _vm._e(), _c('div', {
    staticClass: "consumption-box"
  }, [_c('div', {
    staticClass: "row align-items-center"
  }, [_c('div', {
    staticClass: "col-md-5"
  }, [_c('div', {
    staticClass: "font-w600 text-muted font-size-h4"
  }, [_c('i', {
    staticClass: "fa fa-wind-turbine mr-1"
  }), _vm._v(" Utility Renewables")])]), _c('div', {
    staticClass: "col"
  }, [_c('div', {
    staticClass: "font-w600 text-muted"
  }, [_c('i', {
    staticClass: "fa fa-bolt mr-1 text-warning"
  })]), _c('span', {
    staticClass: "font-w600 font-size-h3"
  }, [_vm._v(_vm._s(_vm.contractRenewables.elec))])]), _c('div', {
    staticClass: "col"
  }, [_c('div', {
    staticClass: "font-w600 text-muted"
  }, [_c('i', {
    staticClass: "fa fa-fire-flame-simple mr-1 text-danger"
  })]), _c('span', {
    staticClass: "font-w600 font-size-h3"
  }, [_vm._v(_vm._s(_vm.contractRenewables.gas))])])])])]), _c('div', {
    staticClass: "col-md-9"
  }, [_c('div', {
    staticClass: "row mb-3"
  }, [_c('div', {
    staticClass: "col"
  }, [_c('div', {
    staticClass: "consumption-box mb-2"
  }, [_c('div', {
    staticClass: "font-w600 text-muted mb-2 font-size-h4"
  }, [_c('i', {
    staticClass: "fa fa-bolt mr-1 text-warning"
  }), _vm._v(" Electricity")]), _c('div', {
    staticClass: "mb-0"
  }, [_c('span', {
    staticClass: "font-w600 font-size-h2"
  }, [_vm._v(" " + _vm._s(_vm._f("numberFormat")(_vm.consumptionSummary.elec.total / _vm.floorAreaMetric, _vm.consumptionSummary.elec.total / _vm.floorAreaMetric < 10 ? 2 : 0)))]), _c('span', {
    staticClass: "font-w600 text-muted"
  }, [_vm._v(" kWh/m"), _c('sup', [_vm._v("2")])])]), _c('div', {
    staticClass: "small font-w600"
  }, [_c('PctDiff', {
    attrs: {
      "value": _vm.consumptionSummary.elec.total,
      "prev-value": _vm.consumptionSummary.elec.prev,
      "comparison-period": "year"
    }
  })], 1)])]), _c('div', {
    staticClass: "col"
  }, [_c('div', {
    staticClass: "consumption-box mb-2"
  }, [_c('div', {
    staticClass: "font-w600 text-muted mb-2 font-size-h4"
  }, [_c('i', {
    staticClass: "fa fa-fire-flame-simple mr-1 text-danger"
  }), _vm._v(" Gas")]), _c('div', {
    staticClass: "mb-0"
  }, [_c('span', {
    staticClass: "font-w600 font-size-h2"
  }, [_vm._v(" " + _vm._s(_vm._f("numberFormat")(_vm.consumptionSummary.gas.total / _vm.floorAreaMetric, _vm.consumptionSummary.gas.total / _vm.floorAreaMetric < 10 ? 2 : 0)))]), _c('span', {
    staticClass: "font-w600 text-muted"
  }, [_vm._v(" kWh/m"), _c('sup', [_vm._v("2")])])]), _c('div', {
    staticClass: "small font-w600"
  }, [_c('PctDiff', {
    attrs: {
      "value": _vm.consumptionSummary.gas.total,
      "prev-value": _vm.consumptionSummary.gas.prev,
      "comparison-period": "year"
    }
  })], 1)])]), _c('div', {
    staticClass: "col"
  }, [_c('div', {
    staticClass: "consumption-box mb-2"
  }, [_c('div', {
    staticClass: "font-w600 text-muted mb-2 font-size-h4"
  }, [_c('i', {
    staticClass: "fa fa-solar-panel mr-1 text-success"
  }), _vm._v(" Solar")]), _c('div', {
    staticClass: "mb-0"
  }, [_c('span', {
    staticClass: "font-w600 font-size-h2"
  }, [_vm._v(" " + _vm._s(_vm._f("numberFormat")(_vm.consumptionSummary.solar.total / _vm.floorAreaMetric, _vm.consumptionSummary.solar.total / _vm.floorAreaMetric < 10 ? 2 : 0)))]), _c('span', {
    staticClass: "font-w600 text-muted"
  }, [_vm._v(" kWh/m"), _c('sup', [_vm._v("2")])])]), _c('div', {
    staticClass: "small font-w600"
  }, [_c('PctDiff', {
    attrs: {
      "value": _vm.consumptionSummary.solar.total,
      "prev-value": _vm.consumptionSummary.solar.prev,
      "comparison-period": "year"
    }
  })], 1)])]), _c('div', {
    staticClass: "col"
  }, [_c('div', {
    staticClass: "consumption-box mb-2"
  }, [_c('div', {
    staticClass: "font-w600 text-muted mb-2 font-size-h4"
  }, [_c('i', {
    staticClass: "fa fa-faucet mr-1 text-info"
  }), _vm._v(" Water")]), _c('div', {
    staticClass: "mb-0"
  }, [_c('span', {
    staticClass: "font-w600 font-size-h2"
  }, [_vm._v(" " + _vm._s(_vm._f("numberFormat")(_vm.consumptionSummary.water.total / _vm.floorAreaMetric, _vm.consumptionSummary.water.total / _vm.floorAreaMetric < 10 ? 2 : 0)))]), _c('span', {
    staticClass: "font-w600 text-muted"
  }, [_vm._v(" m"), _c('sup', [_vm._v("3")]), _vm._v("/m"), _c('sup', [_vm._v("2")])])]), _c('div', {
    staticClass: "small font-w600"
  }, [_c('PctDiff', {
    attrs: {
      "value": _vm.consumptionSummary.water.total,
      "prev-value": _vm.consumptionSummary.water.prev,
      "comparison-period": "year"
    }
  })], 1)])])]), _c('div', {
    staticClass: "row mb-1"
  }, [_c('div', {
    staticClass: "col-md-6"
  }, [_c('div', {
    staticClass: "consumption-box mb-4"
  }, [_c('div', {
    staticClass: "font-w600 text-muted mb-0 font-size-h5"
  }, [_c('i', {
    staticClass: "fa fa-bolt mr-1"
  }), _vm._v(" Electricity & Gas Consumption")]), (_vm$analytics$elecCo = _vm.analytics['elec-consumption']) !== null && _vm$analytics$elecCo !== void 0 && (_vm$analytics$elecCo$ = _vm$analytics$elecCo.results) !== null && _vm$analytics$elecCo$ !== void 0 && _vm$analytics$elecCo$.monthlyConsumption ? _c('apexchart', {
    attrs: {
      "height": "364",
      "options": _vm.benchmarkConsumptionLineChart.options,
      "series": _vm.benchmarkConsumptionLineChart.series
    }
  }) : _vm._e()], 1)]), _c('div', {
    staticClass: "col-md-6"
  }, [_c('div', {
    staticClass: "consumption-box mb-4"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-6"
  }, [_c('div', {
    staticClass: "font-w600 text-muted mb-0 font-size-h5"
  }, [_c('i', {
    staticClass: "fa fa-faucet mr-1"
  }), _vm._v(" Water Consumption")]), (_vm$analytics$waterC = _vm.analytics['water-consumption']) !== null && _vm$analytics$waterC !== void 0 && (_vm$analytics$waterC$ = _vm$analytics$waterC.results) !== null && _vm$analytics$waterC$ !== void 0 && _vm$analytics$waterC$.monthlyConsumption ? _c('apexchart', {
    attrs: {
      "height": "142",
      "options": _vm.consumptionCharts.water.options,
      "series": _vm.consumptionCharts.water.series
    }
  }) : _vm._e()], 1), _c('div', {
    staticClass: "col-md-6"
  }, [_c('div', {
    staticClass: "font-w600 text-muted mb-0 font-size-h5"
  }, [_c('i', {
    staticClass: "fa fa-solar-panel mr-1"
  }), _vm._v(" Solar Generation")]), (_vm$analytics$solarC = _vm.analytics['solar-consumption']) !== null && _vm$analytics$solarC !== void 0 && (_vm$analytics$solarC$ = _vm$analytics$solarC.results) !== null && _vm$analytics$solarC$ !== void 0 && _vm$analytics$solarC$.monthlyConsumption ? _c('apexchart', {
    attrs: {
      "height": "143",
      "options": _vm.consumptionCharts.solar.options,
      "series": _vm.consumptionCharts.solar.series
    }
  }) : _vm._e()], 1)])]), _c('div', {
    staticClass: "consumption-box consumption-box--trimmed mb-4"
  }, [_c('div', {
    staticClass: "font-w600 text-muted mb-0 font-size-h5"
  }, [_c('i', {
    staticClass: "fa fa-line-chart mr-2"
  }), _vm._v("Benchmarks")]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-6"
  }, [(_vm$analytics$elecCo2 = _vm.analytics['elec-consumption']) !== null && _vm$analytics$elecCo2 !== void 0 && _vm$analytics$elecCo2.results ? _c('apexchart', {
    attrs: {
      "height": "145",
      "options": _vm.elecBenchmarkChart.options,
      "series": _vm.elecBenchmarkChart.series
    }
  }) : _vm._e()], 1), _c('div', {
    staticClass: "col-md-6"
  }, [(_vm$analytics$elecCo3 = _vm.analytics['elec-consumption']) !== null && _vm$analytics$elecCo3 !== void 0 && _vm$analytics$elecCo3.results && (_vm$analytics$gasCon = _vm.analytics['gas-consumption']) !== null && _vm$analytics$gasCon !== void 0 && _vm$analytics$gasCon.results ? _c('apexchart', {
    attrs: {
      "height": "145",
      "options": _vm.combinedBenchmarkChart.options,
      "series": _vm.combinedBenchmarkChart.series
    }
  }) : _vm._e()], 1)])])])]), _c('div', {
    staticClass: "consumption-box"
  }, [_c('div', {
    staticClass: "d-flex justify-content-between align-items-start"
  }, [_c('div', {
    staticClass: "font-w600 text-muted mb-3 font-size-h3"
  }, [_c('i', {
    staticClass: "fa fa-leaf mr-1 text-success"
  }), _vm._v(" Emissions")]), _c('p', {
    staticClass: "font-size-sm font-w600 text-muted mb-0"
  }, [_vm._v("The below values are all calculated using the BEIS emissions factors for 2023.")])]), _c('div', {
    staticClass: "border-bottom pb-3"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col"
  }, [_c('div', {
    staticClass: "font-w600 text-muted mb-1"
  }, [_vm._v("Scope 1")]), _c('div', [_c('span', {
    staticClass: "font-w600 font-size-h3"
  }, [_vm._v(_vm._s(_vm._f("numberFormat")(_vm.emissionSummary.scope1.total, 0)) + " ")]), _c('small', {
    staticClass: "font-w600 text-muted"
  }, [_vm._v("kg CO"), _c('sub', [_vm._v("2")]), _vm._v("e")])]), _c('div', {
    staticClass: "small font-w600"
  }, [_c('PctDiff', {
    attrs: {
      "value": _vm.emissionSummary.scope1.total,
      "prev-value": _vm.emissionSummary.scope1.prev[0],
      "comparison-period": "year"
    }
  })], 1)]), _c('div', {
    staticClass: "col"
  }, [_c('div', {
    staticClass: "font-w600 text-muted mb-1"
  }, [_vm._v("Scope 2")]), _c('div', [_c('span', {
    staticClass: "font-w600 font-size-h3"
  }, [_vm._v(_vm._s(_vm._f("numberFormat")(_vm.emissionSummary.scope2.total, 0)) + " ")]), _c('small', {
    staticClass: "font-w600 text-muted"
  }, [_vm._v("kg CO"), _c('sub', [_vm._v("2")]), _vm._v("e")])]), _c('div', {
    staticClass: "small font-w600"
  }, [_c('PctDiff', {
    attrs: {
      "value": _vm.emissionSummary.scope2.total,
      "prev-value": _vm.emissionSummary.scope2.prev[0],
      "comparison-period": "year"
    }
  })], 1)]), _c('div', {
    staticClass: "col"
  }, [_c('div', {
    staticClass: "font-w600 text-muted mb-1"
  }, [_vm._v("Scope 3")]), _c('div', [_c('span', {
    staticClass: "font-w600 font-size-h3"
  }, [_vm._v(_vm._s(_vm._f("numberFormat")(_vm.emissionSummary.scope3.total, 0)) + " ")]), _c('small', {
    staticClass: "font-w600 text-muted"
  }, [_vm._v("kg CO"), _c('sub', [_vm._v("2")]), _vm._v("e")])]), _c('div', {
    staticClass: "small font-w600"
  }, [_c('PctDiff', {
    attrs: {
      "value": _vm.emissionSummary.scope3.total,
      "prev-value": _vm.emissionSummary.scope3.prev[0],
      "comparison-period": "year"
    }
  })], 1)]), _c('div', {
    staticClass: "col"
  }, [_c('div', {
    staticClass: "font-w600 text-muted mb-1"
  }, [_vm._v("Total")]), _c('div', [_c('span', {
    staticClass: "font-w600 font-size-h3"
  }, [_vm._v(_vm._s(_vm._f("numberFormat")(_vm.emissionSummary.combined.total, 0)) + " ")]), _c('small', {
    staticClass: "font-w600 text-muted"
  }, [_vm._v("kg CO"), _c('sub', [_vm._v("2")]), _vm._v("e")])]), _c('div', {
    staticClass: "small font-w600"
  }, [_c('PctDiff', {
    attrs: {
      "value": _vm.emissionSummary.combined.total,
      "prev-value": _vm.emissionSummary.combined.prev[0],
      "comparison-period": "year"
    }
  })], 1)]), _c('div', {
    staticClass: "col"
  }, [_c('div', {
    staticClass: "font-w600 text-muted mb-1"
  }, [_vm._v("Intensity")]), _c('div', [_c('span', {
    staticClass: "font-w600 font-size-h3"
  }, [_vm._v(_vm._s(_vm._f("numberFormat")(_vm.emissionSummary.intensity.total, 0)) + " ")]), _c('small', {
    staticClass: "font-w600 text-muted"
  }, [_vm._v("kg CO"), _c('sub', [_vm._v("2")]), _vm._v("e/m"), _c('sup', [_vm._v("2")])])]), _c('div', {
    staticClass: "small font-w600"
  }, [_c('PctDiff', {
    attrs: {
      "value": _vm.emissionSummary.intensity.total,
      "prev-value": _vm.emissionSummary.intensity.prev[0],
      "comparison-period": "year"
    }
  })], 1)])])]), _c('div', {
    staticClass: "row align-items-center"
  }, [_c('div', {
    staticClass: "col-md-2"
  }, [_c('apexchart', {
    attrs: {
      "height": "220",
      "options": _vm.scopeBreakdownChart.options,
      "series": _vm.scopeBreakdownChart.series
    }
  })], 1), _c('div', {
    staticClass: "col-md-5"
  }, [_c('apexchart', {
    attrs: {
      "height": "220",
      "options": _vm.emissionsBreakdownChart.options,
      "series": _vm.emissionsBreakdownChart.series
    }
  })], 1), _c('div', {
    staticClass: "col-md-5"
  }, [(_vm$analytics$solarC2 = _vm.analytics['solar-consumption']) !== null && _vm$analytics$solarC2 !== void 0 && (_vm$analytics$solarC3 = _vm$analytics$solarC2.results) !== null && _vm$analytics$solarC3 !== void 0 && _vm$analytics$solarC3.monthlyConsumption ? _c('apexchart', {
    attrs: {
      "height": "200",
      "options": _vm.emissionsChartLine.options,
      "series": _vm.emissionsChartLine.series
    }
  }) : _vm._e()], 1)])])])])])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }